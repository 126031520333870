import { Observable } from 'rxjs';
import { Roles } from '../utils/constants';
import { Branch } from './branches';
import { Permissions, Role } from './lookup';

export interface User {
  phoneNumber: string;
  email: string;
  id?: string,
  firstName: string;
  lastName: string
  picture?: string;
  qualifications?: string;
  role: Role,
  branch: Branch,
  rolePermissions: Array<Permissions>
  signature: string
}

export interface Profile {
  firstName: string,
  lastName: string,
  qualifications: string,
  branchID: string,
  branch: any,
  signature: string,
  email: string,
  phoneNumber: number
}
export abstract class UserData {

  abstract getUsers(): Observable<User[]>;
  abstract addUser(user: User): Observable<User>;
  abstract getUserById(id: string): Observable<User>;
  abstract updateUser(user: User): Observable<User>;
  abstract deleteUser(user: User): Observable<User>;
  abstract getUserProfile(): Observable<Profile>;
  abstract updateUserProfile(profile: Profile):Observable<Profile>;
}
