import { Location } from '@angular/common';
import { Component, Input, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ngx-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss']
})
export class PageHeadingComponent implements OnInit {

  @Input() heading;
  @Input() link;
  items = [
    { title: 'Preview' },
    { title: 'Logout' },
  ];


  constructor(private location: Location,
    private nbMenuService: NbMenuService,
    @Inject(NB_WINDOW) private window) { }

  ngOnInit(): void {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if(title === 'Preview') {

        }
      });
  }

  back() {
    this.location.back()
  }

}
