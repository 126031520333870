import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { share, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Notification } from '../data/notification';
import { NOTIFICATION_REFRESH_INTERVAL } from '../utils/constants';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationList$: BehaviorSubject<Array<Notification>> = new BehaviorSubject([]);

  constructor(private authService: AuthService, private http: HttpClient) {
    this.getNotifications();
  }

  public getNotifications() {
    let claims: any = this.authService.identityClaims;
    if (!claims) {
      return;
    }
    let branchId = claims.BranchID;
    let roleIds = claims.RoleID;

    if (!(roleIds instanceof Array)) {
      roleIds = [roleIds];
    }


    timer(1, NOTIFICATION_REFRESH_INTERVAL).pipe(
      switchMap(() => this.http.post(environment.notificationUrl + `/Notification/GetNotifications/${branchId}`, roleIds)),
      share()
    )
      .subscribe((notifications: any) => {
        this.notificationList$.next(notifications.result);
      });
  }

  public markAsRead(notification: Notification) {
    this.http.post(environment.notificationUrl + `/Notification/SetReadNotification/`, [{ notificationID: notification.id, isActionTaken: true}])
      .pipe(take(1))
      .subscribe(() => {
        this.getNotifications();
      })
  }

  get notifcations$() {
    return this.notificationList$;
  }

  registerDevice(notificationPayload: any) {
    return this.http.post(environment.notificationUrl + '/Notification/RegisterFirebaseDevice', notificationPayload);
  }

}
