import { Directive, ElementRef, HostListener, Input, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[parseName]'
})

export class ModelWithNameDirective {
   @Input('parseName') key:any;
    defaultKey = 'name';
    constructor(private model: NgControl, protected hostRef: ElementRef,) { }
    ngOnInit() {

        if (this.model.control.value && this.model.control.value[this.key || this.defaultKey]) {
            let value = this.model.control.value[this.key || this.defaultKey];
            if(this.model.control.value.branchCode)
                value = this.model.control.value[this.key || this.defaultKey] + ' (' + this.model.control.value.branchCode + ')';
            this.hostRef.nativeElement.value = value;
        }

        this.model.control.valueChanges.subscribe((e) => {
            if (this.model.control.value && this.model.control.value[this.key || this.defaultKey]) {
                this.hostRef.nativeElement.value = this.model.control.value[this.key || this.defaultKey];
            }
        });

    }

}
