import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { ApiError, ApiResponse } from '../data/api.response';
import { VesselMaster, VesselMasterData } from '../data/vesselMaster';

@Injectable({
  providedIn: 'root'
})
export class VesselMasterService {
  

  constructor(private http: HttpClient) { }

  getVessels(): Observable<VesselMaster[]> {
    // console.log("gettling vessels");
    // return of(this.vessels);
    return this.http.get<ApiResponse>('/Vessel/AllVessels').pipe(retry(3), switchMap(x => {
        return of(x.result as VesselMaster[]);
    }));
}

createVessel(vesselData: any): Observable<VesselMaster> {
  return this.http.post<ApiResponse>('/Vessel/AddVessel', vesselData).pipe(retry(3), switchMap(x => {
      return of(x.result as VesselMaster);
  }));
}

getVesselById(id: string | number): Observable<VesselMaster> {
  // return of(this.vessels.find(v => v.imoNo == id));
  return this.http.get<ApiResponse>(`/Vessel/VesselByID/${id}`).pipe(retry(3), switchMap(x => {
      return of(x.result as VesselMaster);
  }));
}


updateVessel(vesselData: any): Observable<any> {
  return this.http.put<ApiResponse>(`/Vessel/UpdateVessel/${vesselData.id}`, vesselData).pipe(retry(3), switchMap(x => {
      return of(x.result as VesselMaster);
  }));
}

deleteVessel(vesselData: any): Observable<any> {
  return this.http.delete<ApiResponse>(`/Vessel/DeleteVessel/${vesselData.id}`,).pipe(retry(3), switchMap(x => {
      return of(x.result as VesselMaster);
  }));
}
search(searchString: string): Observable<VesselMaster[]> {
  return this.http.get<ApiResponse>(`/Vessel/Vessels/${searchString}`).pipe(retry(3), catchError(err => of(new ApiError([]))), switchMap(x => {
      return of(x.result as VesselMaster[]);
  }));
}

}
