<div class="message message--confirmation open" id="modalDeleteClient">
    <div class="message__header">
        <h3 class="message__title" style="padding-left: 12rem;">Missing {{ formType }} {{field.fieldName }}</h3>
        <button type="button" class="btn btn--icon" (click)="close1()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="message__body message__body--confirmation">
        <p>
            <i class="material-icons">warning</i>
        </p>
        <p>{{ formType }} {{field.fieldName }} is missing!</p>
    </div>

    <div class="message__actions message__actions--form" *ngIf="isInitialForm()">
        <button type="button" class="btn btn--primary btn--inline" style="width: 40rem;" (click)="goToInitialForm(formType)">Go to Initial  Survey
        </button>
        
    </div>
    <div class="message__actions message__actions--form" *ngIf="isFinalForm()">
        
        <button type="button" class="btn btn--primary btn--inline" style="width: 40rem;" (click)="goToFinalForm(formType)">Go to Final  Survey</button>
    </div>
</div>