import { Component, OnInit, Input, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Observable, Subscription } from 'rxjs';
import { JobsData } from '../../../@core/data/jobs';
import { FileUploadValidatorService } from '../../../@core/services/file-upload-validator.service';
import { finalize, map, take } from 'rxjs/operators';
import { APP_MESSAGES } from '../../../@core/utils/constants';



@Component({
  selector: 'ngx-edit-photo',
  templateUrl: './edit-photo.component.html',
  styleUrls: ['./edit-photo.component.scss']
})
export class EditPhotoComponent implements OnInit {
  @Input() jobAttachment: any;
  @Input() formData: FormData;
  @Input() fileSrc: any;
  @Input() fileType: any;
  @Input() jobID: string;

  modalTitle: string;
  errorMessage = null;
  showLoading$: Observable<boolean>;
  uploadedFileResponse: boolean= false;
  fileUploadSuccess = false;
  subscriptions:Subscription [] = [];
  title: string;
  description: string;

  isPhoto = false;
  isVideo = false;

  isPhotoURL = false;
  isVideoURL = false;

  attachmentForm: FormGroup;

  constructor(private dialogRef: NbDialogRef<EditPhotoComponent>,
    private fileValidatorService: FileUploadValidatorService,
    private formBuilder: FormBuilder,
    private santizer: DomSanitizer,
    private toastrService: NbToastrService,
    private jobsData: JobsData,
    ) { }

  ngOnInit(): void {
    this.modalTitle = this.jobAttachment ? "Edit Attachment" : "Add Attachment";
    this.attachmentForm = this.formBuilder.group({
      File: [''],
      Title: [this.jobAttachment.title ? this.jobAttachment.title : '' , Validators.required],
      Description: [this.jobAttachment.description ? this.jobAttachment.description : '', Validators.required]
    });
    this.isPhotoOrVideo();
    this.isUrlPhotOrVideo();

    // this.isImage(this.fileType);
    // console.log("formData : ",this.fileType);

  }

  ngOnDestroy() {
    this.fileUploadSuccess = false;
    this.resetImgVidTag();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  sanitizeURL(url){
    return this.santizer.bypassSecurityTrustResourceUrl(url);
  }

  sanitizeVideo(url){
    return this.santizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(url));
  }

  isPhotoOrVideo() {
    if(this.fileSrc){
      if(this.fileType.includes('image')) {
        // console.log(this.fileSrc);
        this.fileSrc = this.sanitizeURL(this.fileSrc.changingThisBreaksApplicationSecurity);
        this.isVideo = false;
        return this.isPhoto = true;
      } else {
        this.fileSrc = this.sanitizeURL(this.fileSrc.changingThisBreaksApplicationSecurity);
        this.isPhoto = false;
        return this.isVideo = true;
      }
    }
  }

  isUrlPhotOrVideo(){
    if(this.jobAttachment) {
      this.resetImgVidTag();
      // if(this.jobAttachment.viewURL.includes('.png') || this.jobAttachment.viewURL.includes('.jpeg') ||
      //     this.jobAttachment.viewURL.includes('.jpg') || this.jobAttachment.viewURL.includes('.PNG') ||
      //     this.jobAttachment.viewURL.includes('.gif') || this.jobAttachment.viewURL.includes('.jfif') )
        if(this.fileValidatorService.isPhotoUrl(this.jobAttachment.viewURL)) {
          return this.isPhotoURL = true;
        }
      return this.isVideoURL = true;
    }
  }

  resetImgVidTag(){
    this.isPhoto = false;
    this.isVideo = false;
  }

  resetImgVidTagURL(){
    this.isPhotoURL = false;
    this.isVideoURL = false;
  }

  onDismis(){
    this.errorMessage = null;
    this.resetImgVidTag();
    this.fileUploadSuccess = false;
    this.close();
  }

  get formControls() { return this.attachmentForm.controls; }

  get file() { return this.formData.get('File'); }

  onDescriptionChange(){
    if(this.modalTitle === 'Edit Attachment')
    {
      this.formControls.Title.clearValidators();
      this.formControls.Title.updateValueAndValidity();
    }
  }

  handleImage = async (event) => {
    const file = event.target.files[0];
    const fileExtension = this.fileValidatorService.getFileExtension(file);

    if(!this.fileValidatorService.isSupportedExtension(fileExtension)) {
      this.formControls.File.reset();
      return this.toastrService.danger("", APP_MESSAGES.FILE_NOT_SUPPORTED);
    }

    if(this.fileValidatorService.isFileLarger(file)) {
      this.formControls.File.reset();
      return this.toastrService.danger("", APP_MESSAGES.FILE_TOO_LARGE);
    }
    this.disableValidatorsOnImgChange();

    try {
        this.fileSrc = this.sanitizeVideo(file);
        this.fileType = file.type;
        // this.fileSrc ? this.jobAttachment = null: this.fileSrc;
        this.resetImgVidTagURL();
        this.isPhotoOrVideo();
        this.formData.set('File', file, file.name);
    } catch (e) {
      console.warn(e.message)
    }
  }

  disableValidatorsOnImgChange(){
    if(this.modalTitle === 'Edit Attachment')
    {
      this.formControls.Title.clearValidators();
      this.formControls.Description.clearValidators();

      this.formControls.Description.updateValueAndValidity();
      this.formControls.Title.updateValueAndValidity();
    }
  }

  getFormControlValues() {
    if (this.attachmentForm.invalid){
      return;
    }
    else{
      this.title = this.formControls.Title.value;
      this.description = this.formControls.Description.value;
      this.modalTitle === 'Edit Attachment' ? this.updateJobAttchment()  : this.uploadFile();
    }
  }

  updateJobAttchment() {
    if(this.file){
      this.updateJobAttachmentWithFile();
    }
    else {
      this.updateJobAttachmentWithOutFile();
    }

  }

  updateJobAttachmentWithFile() {
    this.jobsData.uploadAttachment(this.jobID, this.formData).subscribe(res=> {
      let payload= {
        id: this.jobAttachment.id,
        jobId: this.jobID,
        title: this.title,
        fileID: res[0].result.fileID,
        description: this.description,
        comments: 'none',
        viewURL: res[0].result.fileUrl
      }
      if(res[0].status){
        this.toastrService.success("", 'Attachment Uploaded succefully!!');
      }
      this.jobsData.updateAttachmentToJob(this.jobAttachment.id, payload).subscribe(res =>{
        this.jobAttachment = res.result;
        if(res) {
          this.formData.delete('File');
          this.dialogRef.close(true);
        }
      })
    });
    this.getFileUploadResponse();
    this.getError();
  }

  updateJobAttachmentWithOutFile() {
    let payload= {
      id: this.jobAttachment.id,
      jobId: this.jobAttachment.id,
      fileID: this.jobAttachment.fileID,
      viewURL: this.jobAttachment.viewURL,
      title: this.title,
      description: this.description,
    }
    this.jobsData.updateAttachmentToJob(this.jobAttachment.id, payload).subscribe(res=> {
      if(res) {
        this.dialogRef.close(true);
      }
    })
  }
  // inputChanged($event){
  //   setTimeout(() => {
  //     this.predefinedLabels =[]
  //   }, 150);
  //   this.selectedOption = $event.target.value
  // }
  uploadFile() {
    this.jobsData.uploadAttachment(this.jobID, this.formData).subscribe(res=>
    {
      let payload= {
        'jobId': this.jobID,
        'comments': 'none',
        'description': this.description,
        'title': this.title,
        'fileID': res[0].result.fileID,
        'viewURL': res[0].result.viewURL
      }
      if(res[0].status){
        this.toastrService.success("", 'Attachment Uploaded succefully!!');
      }
      this.jobsData.addAttachmentToJob(payload).subscribe(res => {
        this.jobAttachment = res.result;
        if(res.status) {
          this.formData.delete('File');
          this.dialogRef.close(true);
        }
      })
    })
  }

  getFileUploadResponse() {
    // this.uploadedFileResponse$ = this.store.select(getUploadedFile);
    // let subscription = this.uploadedFileResponse$.subscribe(
    //   (res) => {
    //     this.fileUploadSuccess = res;
    //     this.formData.delete('File');
    //     this.close();
    //   }
    // );
    // this.subscriptions.push(subscription);
  }

  getError(){
    // let subscription = this.store.select(getFileUploadError)
    //   .subscribe(res => {
    //     if(res) {
    //       this.errorMessage = res;
    //       setTimeout(
    //         () => this.onDismis(),
    //         2000
    //         );
    //     }
    // })

    // this.subscriptions.push(subscription);
  }

  onSubmit() {
    this.getFormControlValues();
    this.resetImgVidTag();
  }

  close() {
    this.dialogRef.close(false);
  }
}
