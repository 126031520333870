<form [formGroup]="commentsForm">
<div class="modal open" id="modalComments" style="height: auto;">
    <div class="modal__header">
       <h2>Attachment Comments</h2>
       <button type="button" class="btn btn--icon" (click)="close()">
          <i class="material-icons">close</i>
       </button>
    </div>
    <div class="modal__content">

       <ul class="list-comments">
          <li class="comment">
             <!-- <span class="comment__date">2 June 2021 3:40 pm</span> -
             <span class="comment__user">James Wilson</span>
             <div class="comment__text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur voluptate et dolore delectus! A aspernatur eaque, cupiditate blanditiis iusto corrupti?
             </div> -->
             <li class="comment" *ngFor="let comments of attachmentComments; let i = index" style="margin-bottom: -2.5rem">
                <div [ngClass] = "isMyComment(comments) ? 'shift-right' : 'shift-left'">
               <span class="comment__date">{{comments?.commentedDateTime | localDatetime|date: "dd MMM yyyy HH:mm"}}</span> -
               <span class="comment__user">{{ isMyComment(comments) ? 'You' : comments.user?.firstName }}</span>
               <div class="comment__text" [ngClass] = "isMyComment(comments) ? '' : 'wid'">
                   {{comments.comment}}
               </div>
             <!-- <div class="comment__actions">
                <a href="javascript:;">Reply</a>
             </div> -->
             <textarea *ngIf="editing && replyIndex == i" class="form-control form-control__textarea"
                        placeholder="Reply" formControlName="textareaReply" name="textareaReply"></textarea>
                    <!-- <button *ngIf="replyIndex != i" type="button" status="primary"
                        class="commentsTagsReply commentsButtons" (click)="onreplyclick(i)">Reply</button> -->
                        <a *ngIf="replyIndex != i" class="commentsTagsReply commentsButtons" (click)="onreplyclick(i)" class="btnSize">Reply</a>&nbsp;
                        <a *ngIf="replyIndex != i && isMyComment(comments)" (click)="oneditclick1(comments)" class="btnSize">Edit</a>&nbsp;
                        <a *ngIf="replyIndex != i && isMyComment(comments)" (click)="delete(comments)" class="btnSize">Delete</a>
                    <!-- <button *ngIf="editing && replyIndex == i" type="button" status="primary"
                        class="commentsTagsReply commentsButtons" (click)="replycomment(comments)">Add</button>&nbsp; -->
                       <a *ngIf="editing && replyIndex == i" (click)="replycomment(comments)" class="btnSize">Add</a>&nbsp;

                    <!-- <button *ngIf="editing && replyIndex == i" type="button" status="primary" (click)="cancelReply(i)"
                        class="commentsTagsReply commentsButtons">Cancel</button> -->
                        <a *ngIf="editing && replyIndex == i" (click)="cancelReply(i)" class="btnSize">Cancel</a>

             <!-- Nested Comment -->
             <!-- <ul class="list-comments list-comments--nested">
                <li class="comment">
                   <span class="comment__date">2 June 2021 4:40 pm</span> -
                   <span class="comment__user">You</span>
                   <div class="comment__text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur voluptate et dolore delectus! A aspernatur eaque, cupiditate blanditiis iusto corrupti?
                   </div>
                   <div class="comment__actions">
                      <a href="javascript:;">Edit</a>
                      <a href="javascript:;">Delete</a>
                   </div>
                </li>
             </ul> -->
             <ul class="list-comments list-comments--nested">
               <li class="comment">
                   <div class="editDiv" *ngFor="let subcmnts of comments.subComment; let j = index" style="margin-bottom: 1rem;">


                       <span class="comment__date">{{subcmnts?.commentedDateTime | localDatetime|date: "dd MMM yyyy HH : mm"}}</span> -
                       <span class="comment__user">{{ isMyComment(subcmnts) ? 'You' :
                           subcmnts.user?.firstName }}</span>

                       <div  class="comment__text" [ngClass] = "isMyComment(comments) ? '' : 'wid'">
                           {{subcmnts.comment}}
                       </div>

                       <!-- <button type="button" status="primary" (click)="oneditclick1(subcmnts)"
                           class="commentsTagsReply commentsButtons">Edit</button>&nbsp; -->
                           <div *ngIf="isMyComment(subcmnts)">
                            <a (click)="oneditclick1(subcmnts)" class="btnSize">Edit</a>&nbsp;
                            <a (click)="delete(subcmnts)" class="btnSize">Delete</a>
                           </div>

                       <!-- <button type="button" status="primary" (click)="delete(subcmnts)"
                           class="commentsTagsReply commentsButtons">Delete</button> -->

                   </div>
               </li>
           </ul>
        </div>
          </li>
       </ul>

    </div>
    <div class="modal__comment-form">
       <!-- <form class="form">
          <textarea class="form-control form-control__textarea" placeholder="Enter your comments"></textarea>
          <div class="form__actions">

             <button type="button" class="btn btn--primary btn--condensed" style="float: right;">
                Add Comment
             </button>
         </div>
         </form> -->

         <div *ngIf="!makeEdit">
            <textarea class="form-control form-control__textarea" placeholder="Enter your comments"
                formControlName="textarea" name="textarea"></textarea>


            <button type="button" class="btn btn--primary btn--condensed" style="float: right;margin-top: 1rem;"
                (click)="addComment()">
                Add Comment
            </button>
        </div>


        <div *ngIf="makeEdit">
            <textarea class="form-control form-control__textarea" placeholder="Edit your comments"
                formControlName="Comment" name="Comment"></textarea>

                <button type="button" class="btn btn--primary btn--condensed"
                style="float: right;margin-top: 1rem;" (click)="cancel()">
                Cancel
            </button>
            <button type="button" class="btn btn--primary btn--condensed" style="float: right;margin-top: 1rem;margin-right: 1rem;"
                (click)="update(subcmnts)">
                Update
            </button>

        </div>
   </div>
</div>
   </form>
