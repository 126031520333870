export interface ApiResponse {

    status: boolean,
    message: string,
    result: any;
}

export class ApiError implements ApiResponse {
    status: boolean = false;
    constructor(public result, public message = "",) {

    }

}