import { Observable } from "rxjs";
import { Branch } from "./branches";
import { Port } from "./ports";
import { Vessel } from "./vessels";

export interface Job {

    reportedDateTime: Date;
    id?: string;
    vessel: Vessel;
    readonly vesselID: String;
    readonly branchID: String;
    portOfLoading: Port,
    portOfDischarging: Port,
    readonly portOfLoadingID: string,
    readonly portOfDischargingID: string,
    agent: string;
    cargo: string;
    branch: Branch;
    eta: Date,
    jobOrder: string;
    jobAttachments: any[];
    draftSurveys: any[];//TODO change to surevey array and create a model
    jobResult: any[];
    jobUniqueID: string;
    clientBusinessName: string;
    Berth: string;
    status: string;
    client: any;
    port: string;
    approvedDateTime: any;
    draftSurveyReportFileID: string;
}
export interface FieldsManagerInput {
    fieldName: string,
    fieldValue: string,
    jobID: string,
    surveyType: "Initial" | "Final",
    lockedBy: string,
}
export interface FieldsManagerOutput {
    fieldName: string,
    fieldValue: string,
    jobID: string,
    surveyType: "Initial" | "Final",
    UnlockedBy: string,
}
export interface SurveyLockedField {
    fieldName: string
    fieldValue: string,
    id: string,
    job: string,
    jobID: string,
    lockedBy: string,
    lockedDate: Date,
    status: "Unlocked" | "Locked",
    tableName: "DraftSurveyTable" | "Jobs",
    user: SurveyUser,
    surveyType?: "Initial" | "Final",
}
export interface JobInfoFieldOutput {
    fieldName: string,
    fieldValue: string,
    id: string,
    job: string,
    jobID: string,
    lockedBy: string,
    lockedDate: Date,
    status: string,
    surveyType: string,
    tableName: string,
    user: SurveyUser
}

export interface CommentsField {
    comment: string;
    commentedBy: string;
    commentedDateTime: string;
    subComment: any[];

}
export interface CommentsCount {
    referenceID: string,
    unreadCount: number
}

export interface addCommentFields {
    jobID: string;
    comment: any;
    commentedBy: string;


}
export interface Replycomments {
    CommentID: string;
    jobID: string;
    comment: any;
    commentedBy: string;

}
export interface SurveyUser {
    country: string,
    email: string,
    id: string,
    isActive: boolean,
    isDeleted: boolean,
    name: string,
    phoneNumber: string,
    title: string,
    userUniqueID: string,
    firstName: string,
    lastName: string,
}

export class SurveyJob implements Job {
    reportedDateTime: Date;
    id?: string;
    clientBusinessName: string;
    vessel: Vessel;
    portOfLoading: Port;
    portOfDischarging: Port;
    Berth: string;
    get portOfLoadingID(): string {
        return this.portOfLoading?.id;
    };
    get portOfDischargingID(): string {
        return this.portOfDischarging?.id;
    };
    get branchID(): string {
        return this.branch?.id;
    };

    get vesselID(): string {
        return this.vessel?.id;
    }
    agent: string;
    cargo: string;
    branch: Branch;
    eta: Date;
    jobOrder: string;
    jobAttachments: any[];
    draftSurveys: any[]//TODO change to surevey array and create a model
    jobResult: any[];
    jobUniqueID: string;
    status: string;
    client: any;
    port: string;
    approvedDateTime;
    draftSurveyReportFileID: string;

    constructor(formData: any) {
        this.vessel = formData.vessel;
        this.jobOrder = formData.jobOrder;
        this.branch = formData.branch;
        this.cargo = formData.cargo;
        this.agent = formData.agent;
        this.portOfDischarging = formData.portOfDischarging;
        this.eta = formData.eta ? new Date(formData.eta) : null;
        this.portOfLoading = formData.portOfLoading;
        this.id = formData.id;
        this.draftSurveys = formData.draftSurveys;
        this.jobResult = formData.jobResult;
        this.jobUniqueID = formData.berth;
        this.clientBusinessName = formData.client.id;
        this.Berth = formData.berth;
        this.status = formData.status;
        this.client = formData.client;
    }



    toJson() {
        return {
            branchId: this.branchID,
            cargo: this.cargo,
            agent: this.agent,
            portOfDischargingID: this.portOfDischargingID,
            portOfLoadingID: this.portOfLoadingID,
            id: this.id,
            eta: this.eta?.toISOString(),
            vesselID: this.vesselID,
            jobOrder: this.jobOrder,
            jobUniqueID: this.jobUniqueID,
            ClientID: this.clientBusinessName,
            Berth: this.Berth,
            status: this.status,
            client: this.client
            //Othe properties which needs to be sent back to server. Survey readings are omitted since signalR will be used
        };
    }
}

export abstract class JobsData {
    abstract getJobs(): Observable<Job[]>;
    abstract createJob(jobData: any): Observable<Job>;
    abstract getJobById(id: string | number): Observable<Job>;
    abstract updateJob(jobData: SurveyJob): Observable<Job>;
    abstract deleteJob(deleteJob: any): Observable<any>;
    abstract getinitialDataJobById(id: string): Observable<Job>;
    abstract getUniqueJobID(): Observable<string>;
    abstract approveJob(id: string | number, status: boolean): Observable<boolean>;
    abstract search(searchString: string): Observable<any>;
    abstract getclientsNames(): Observable<string>;
    abstract calculateSurveyDetails(id: string): Observable<any>;
    abstract getJobsByStatus(status: string): Observable<Job[]>;
    abstract calculateTrim(trimInput: any, trimType: string): Observable<any>;
    abstract saveTrim(trimData: any, trimType: string): Observable<any>;
    abstract getTrimmingDataForJob(jobId: string): Observable<any>;
    abstract getAuditHistory(id: string): Observable<any>;
    abstract getStowageDataForJob(id: String): Observable<any>;
    abstract generateStowagePlan(jobId: string, stowagePlan: any): Observable<any>;
    abstract saveStowagePlan(stowagePlan: any): Observable<any>;
    abstract generateStowagePlanReport(jobID: string): Observable<any>;
    abstract getStowageReportUrls(jobID: string): Observable<any>;
    abstract startSurvey(jobId: string): Observable<any>;
    abstract unlockJobFields(jobId: string): Observable<any>;
    abstract GenerateSurveyReport(jobID: string): Observable<any>;
    abstract GenerateDeadFreightReport(jobID: string): Observable<any>;
    abstract GenerateIntermediateReport(jobID: string, signature: string): Observable<any>;
    abstract viewSurveyReport(jobID: string): Observable<any>;
    abstract resetComments(jobid:string,ReferenceID: string[]): Observable<any>;
    abstract getReportIds(jobID: string,  isDeadFreightReport: boolean): Observable<any>;
    abstract getportsName(portID: string): Observable<any>;
    abstract getAllports(): Observable<any>;
    abstract generateTrimReport(jonID: string, trimType: string): Observable<any>;
    abstract sendReportToClient(jobID: string, emails: Array<string>): Observable<any>;
    abstract checkForHogSag(jobID: string, surveyType: string): Observable<any>;
    abstract GenerateHoldCleanlinessCertificate(jobID: string, action: string, boardingDateTime: any, remarks: any, submittedDateTime: any): Observable<any>;
    abstract GenerateVesselOrHatchInspection(jobID: string, formData: any): Observable<any>;
    abstract GenerateOtherDocuments(jobID: string, formData: any): Observable<any>;
    abstract OtherDocuments(jobID: string, type: any): Observable<any>;
    abstract DeleteAllOtherDocuments(jobID: string): Observable<any>;
    abstract DeleteOtherDocument(jobID: string, fileId: string): Observable<any>;
    abstract GetHcc2SavedImages(jobID: string): Observable<any>;
    abstract sendHccReportToClient(jobID: string, boardingDateTime: string, remarks: any, submittedDateTime:any, emailIDs: Array<string>): Observable<any>;
    abstract uploadAttachment(jobID: string, formData: any): Observable<any>;
    abstract addAttachmentToJob(payload: any): Observable<any>;
    abstract deleteAttachment(attachmentID: string): Observable<any>;
    abstract updateAttachmentToJob(id: string, formData: any): Observable<any>;
    abstract previewSurveyDetails(id: string): Observable<any>;
    abstract SendJobForApproval(id: string): Observable<any>;
    abstract UpdateSignature(formData: any): Observable<any>;
    abstract acknowledgeReport(jobID: string, signature: any): Observable<any>;
    abstract reinspectedHolds(jobID: string): Observable<any>;
    abstract savedReports(jobID: string): Observable<any>;
    // abstract HccSavedReports(jobID: string): Observable<any>;
    abstract SendToClient(jobID: string, fileDetails: any, emails: Array<string>): Observable<any>;
    abstract calculateManualTrim2(trimInput: any, lcgTrimming?: boolean): Observable<any>;
    abstract AcknowledgeTrimmingSurveyDetails(jobID: string, trimType: string, signature: any): Observable<any>;
    abstract UploadLoadingSequenceTemplate(files: File[], jobID: string, worksheetName: string, isSave: Boolean): Observable<any>;
    abstract DeleteHccFiles(jobID: string): Observable<any>;
    abstract JobsByDateRange(fromDate: any, toDate: any): Observable<any>;
    abstract ExportJobsData(payload: any[]): Observable<any>;
    abstract LoadingSequenceDetails(jobID: string): Observable<any>;
    abstract UploadTidesTemplate(files: any, tidesInput: any, payload: any): Observable<any>;
    abstract GetDaysMonthAndYear(): Observable<any>;
    abstract TidesDetailsByDayMonthAndYear( payload: any): Observable<any>;
    abstract GetHccSavedReportsByType(jobID: string, type: any): Observable<any>;
    abstract GetTADetails(jobID: string):Observable<any>;
    abstract SaveTADetails(jobID: string, payload: any):Observable<any>;
    abstract AcknowledgeTADetails(jobID: string, signature: any, chiefOfficerName: string):Observable<any>;
    abstract ExportTADetails(jobID: string): Observable<any>; 
    abstract GetSafetyCheckListItems(): Observable<any>; 
    abstract GenerateSafetyCheckListCertificate(jobID: string, payload: any, action: string): Observable<any>;
    abstract GetSavedSafetyCheckListReport(jobID: string): Observable<any>;

  }
