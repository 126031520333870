import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Profile, UserData } from '../../../@core/data/users';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { Branch, BranchesData } from '../../../@core/data/branches';
import { SignaturePad } from 'angular2-signaturepad';
import { AppMessage } from '../../../@core/utils/constants';


@Component({
  selector: 'ngx-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input('userProfile') userProfile: Profile;
  userForm: FormGroup
  submitted: boolean = false;
  filteredBranches$: Observable<Branch[]>;
  buttonDisable : boolean = false;

  firstName: FormControl;
  lastName: FormControl;
  branchID: FormControl;
  email: FormControl;
  phoneNo: FormControl;

  surveyorSignature: string;
  retrievedSurveyorSignature: string;

  private branchControl: FormControl;

  @ViewChild("surveyorSignature", { static: false }) surveyorSignaturePad: SignaturePad;

  constructor(private dialogRef: NbDialogRef<ProfileComponent>,
    private formBuilder: FormBuilder,
    private branchesData: BranchesData,
    private userData: UserData,
    private toastrService: NbToastrService,
    ) { }

  ngOnInit(): void {
    this.userData.getUserProfile().subscribe(res =>{
      this.userProfile= res;
    })
    this.retrievedSurveyorSignature= this.userProfile.signature
    console.log("this.retrievedSurveyorSignature : ",this.retrievedSurveyorSignature);

    if(this.retrievedSurveyorSignature === null){
      this.retrievedSurveyorSignature= ''
    }

    this.branchControl = new FormControl(this.userProfile.branch.name + ' (' + this.userProfile.branch
    .branchCode + ')', [Validators.required,]);

    this.branchID = new FormControl(this.userProfile.branch.id,Validators.required);
    this.firstName = new FormControl(this.userProfile.firstName,Validators.required)
    this.lastName = new FormControl(this.userProfile.lastName, Validators.required)
    this.email = new FormControl(this.userProfile.email),
    this.phoneNo = new FormControl(this.userProfile.phoneNumber),


    this.userForm = this.formBuilder.group({
      branchID: this.branchID,
      //name: [this.user.name, Validators.required],
      firstname: this.firstName,
      lastname: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNo,
      branch: this.branchControl,
      qualifications: [this.userProfile.qualifications],
      signature: this.surveyorSignature
    });
    console.log("this.branchControl : ",this.branchControl);

    this.filteredBranches$ = this.branchControl.valueChanges
    .pipe(
      filter(v => v && v.length > 2),
      switchMap(filterString => this.branchesData.search(filterString)),
    );
  }

  clearSurveyorSignature(element) {
    element.value = "";
    if(this.retrievedSurveyorSignature !== '') {
      this.retrievedSurveyorSignature= ''
    } else {
      return this.surveyorSignaturePad.clear();
    }
  }

  captureSurveyorSignature() {
    return this.surveyorSignature = this.surveyorSignaturePad.toDataURL();
  }

  updateUser(){
    this.submitted = true;
    // this.buttonDisable = true;
    // if (this.userForm.invalid) {
    //   return this.buttonDisable = false;
    // }
    let branchID = this.userForm.value.branch?.id || this.userForm.value.branchID;
    let signature= this.surveyorSignature !== undefined ? this.surveyorSignature : this.userProfile.signature
    let user = { ...this.userForm.value, branchID, signature };
    delete user.branch;

    this.userData.updateUserProfile(user).subscribe(x => {
      this.toastrService.success(`user ${x.firstName} updated successfully.`, AppMessage.EDIT_USER_SUCCESS);
      this.dialogRef.close(true);
    }, error => {
      let duration = 0;
      let err = error?.error?.message;
      this.toastrService.danger(error?.error?.message,`${err}`, {duration});
      this.buttonDisable = false;

    });
  }

  uploadSignature(event: any){
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      console.log("e.target.result : ",e.target.result);
      this.retrievedSurveyorSignature = e.target.result;
      this.surveyorSignature = this.retrievedSurveyorSignature;
      // Set the signature pad canvas background image to the selected file
      // this.surveyorSignaturePad.clear();
      // this.surveyorSignaturePad.set('backgroundImage', e.target.result);
    };

    reader.readAsDataURL(file);
  }

  getNameAndCode(item: any) {
    return item && item.name && item.branchCode ? item.name + ' (' +  item.branchCode + ')' : item;
  }

  // onDragEnter(event: DragEvent) {
  //   console.log("event : ",event);

  //   event.preventDefault();
  // }

  // onDragOver(event: DragEvent) {
  //   console.log("event : ",event);
  //   event.preventDefault();
  // }

  // onDrop(event: DragEvent) {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files[0];
  //   if (file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.surveyorSignaturePad.fromDataURL(reader.result as string);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }
  close() {
    this.dialogRef.close();
  }

}
