import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, from, Subject, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    public isTokenRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(private authService: AuthService) { }

    getTimeZoneOffset(): string {
        const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

        // Trying to remove date from the string in a locale-agnostic way
        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
            const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
            
            // by this time `trimmed` should be the timezone's name with some punctuation -
            // trim it from both sides
            let timezone = trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
            if(timezone === "Australian Western Standard Time" ) {
                timezone= "W. Australia Standard Time"
            }
            return timezone
        } else {
            // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
            return full;
        }
                // const tzOffset = new Date().toTimeString().match(/([\+-]\d{4})/)[0];
                // return [tzOffset.slice(0, 3), ':', tzOffset.slice(3)].join('');
    } 
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const headers: any = {};
        const culture = navigator.language;

        headers['Ocp-Apim-Subscription-Key'] = environment.apimSubscriptionKey;
        headers['X-Culture'] = culture;
        headers['X-Frame-Options'] = 'SAMEORIGIN';
        headers['X-Content-Type-Options'] = 'nosniff';
        headers['Referrer-Policy'] = 'no-referrer';
        headers['Permissions-Policy'] = ' geolocation=(), microphone=(), camera=()';
        headers['x-timezone'] =  this.getTimeZoneOffset()

        let url = req.url;
        if (!url.startsWith('http')) {
            url = environment.apiUrl + url;
        }

        const apiReq = req.clone({
            url,
            setHeaders: headers,
        });

        return next.handle(apiReq).pipe(
            catchError(error => {
                //This interceptor mainly handles the 401 error and refreshing token.
                // this.logger.logException(error);
                if (
                    req.url.includes("auth/") //Throw all the auth request errors immediately
                ) {
                    //logout user and to redirect him to login page in case of the refresh token expiry.
                    // if (req.url.includes("auth/refresh")) {
                    // this.store.dispatch(Logout());
                    // }
                    return throwError(error);
                }
                if (error.status !== 401) {
                    return throwError(error);
                }
                if (error.status == 401) {
                    this.authService.logout();
                    return throwError(error);
                }
                if (!this.isTokenRefreshing$.value) {
                    if (!this.authService.hasValidToken()) {
                        console.log("Doesnt have a valid access token");
                        this.authService.isAuthenticated$.pipe(filter(auth => !auth), take(1),).subscribe(_ => {
                            this.isTokenRefreshing$.next(true);
                            this.authService.refresh().catch(error => {
                                console.warn("could not refresh token", error);
                                this.authService.login();
                            }).finally(() => {
                                this.isTokenRefreshing$.next(false);
                            })
                        });
                    }
                }


                return this.authService.isAuthenticated$.pipe(filter(auth => auth), take(1), (switchMap(() => next.handle(req).pipe(
                    catchError(error => {
                        return throwError(error);
                    })
                ))));

            })
        );;
    }
}
