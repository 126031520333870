import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ButtonText } from '../../../@core/utils/constants';

@Component({
  selector: 'ngx-dialog',
  styleUrls: ['./confirm-dialog.component.scss'],
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {

  @Input() okButtonText: String = ButtonText.YES;
  @Input() cancelButtonText: String = ButtonText.NO;
  @Input() title: String = '';
  @Input() description: String = "";
  constructor(private dialogRef: NbDialogRef<ConfirmDialogComponent>) {

  }
  ngOnDestroy(): void {

  }
  
  ngOnInit(): void {

  }

  ok() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }

}
