<form [formGroup]="CommentsForm">
    <!-- <p class="commentsTags">{{CommentList?.commentedDateTime | date:'dd MMM yyyy HH:mm '}}
    </p>
    <p *ngIf="!editing">{{CommentList.comment}}</p> -->
    
                <span class="comment__date">{{CommentList?.commentedDateTime | localDatetime | date:'dd MMM yyyy HH:mm'}}</span> -
                <span class="comment__user">{{ isMyComment(CommentList) ? 'You' : CommentList.user?.firstName }}</span>
                
                    <div>
                        <p *ngIf="!editing">{{CommentList.comment}}</p>
                    </div>
                
                
              


    <input *ngIf="editing" nbInput fieldSize="small" formControlName="Comment" name="Comment">
    <button *ngIf="editing" type="button" status="primary" (click)="update()"
        class="commentsTagsReply commentsButtons">Update</button>&nbsp;
    <button *ngIf="editing" type="button" status="primary" (click)="cancel()"
        class="commentsTagsReply commentsButtons">Cancel</button>

    <div class="deleteDiv" *ngIf="!editing">
        <button type="button" status="primary" (click)="oneditclick(j)"
            class="commentsTagsReply commentsButtons">Edit</button>&nbsp;
        <button type="button" status="primary" (click)="delete()"
            class="commentsTagsReply commentsButtons">Delete</button>
    </div>
</form>