import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardWithForcedLogin } from './@core/guards/auth-guard.service';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { RedirectComponent } from './@theme/components/auth/redirect/redirect.component';
import { LoginComponent } from './@theme/components/auth/login/login.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'job/:id',
    redirectTo: 'pages/jobs/:id',
  },
  {
    path: 'job/tidal-data',
    redirectTo: 'pages/jobs/tidal-data',
  },
  // {
  //   path: 'auth',
  //   component: NbAuthComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: NbLoginComponent,
  //     },
  //     {
  //       path: 'login',
  //       component: NbLoginComponent,
  //     },
  //     // {
  //     //   path: 'register',
  //     //   component: NbRegisterComponent,
  //     // },
  //     {
  //       path: 'logout',
  //       component: NbLogoutComponent,
  //     },
  //     // {
  //     //   path: 'request-password',
  //     //   component: NbRequestPasswordComponent,
  //     // },
  //     // {
  //     //   path: 'reset-password',
  //     //   component: NbResetPasswordComponent,
  //     // },
  //   ],
  // },
  // {
  //   path: 'report/:id',
  //   loadChildren: () => import('./pages/survey-report/survey-report.module')
  //       .then(m => m.SurveyReportModule),
  // },
  {
    path: 'callback', redirectTo: '', pathMatch: 'full'
  },
  {
    path: 'should-login',
    component: LoginComponent,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
