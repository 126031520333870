import { Injectable } from '@angular/core';
import { SUPPORTED_PHOTO_FORMATS, SUPPORTED_VIDEO_FORMATS } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class FileUploadValidatorService {
  supportedPhotoFormats = [
    ...SUPPORTED_PHOTO_FORMATS
  ];
  supportedVideoFormats = [
    ...SUPPORTED_VIDEO_FORMATS
  ];
  supportedFileFormats = [
    ...this.supportedPhotoFormats, ...this.supportedVideoFormats
  ];
  constructor() { }
  getFileExtension(file: File) {
    const nameArray = file.name.split('.');
    return nameArray[nameArray.length - 1].toLowerCase();
  }

  isFileLarger(file: File): boolean {
    const maxLimit = 99 * 1024;
    const fileSize = file.size;
    console.log(fileSize, 'maxlimit', maxLimit);
    return (Math.round(fileSize / 1024) > maxLimit);
  }

  isSupportedExtension(extension:string): boolean {
    return this.supportedFileFormats.includes(extension);
  }

  isPhotoUrl(viewURL: string): boolean {
    const isPhotoUrl = this.supportedPhotoFormats.some(format => viewURL.includes(format));
    return isPhotoUrl;
  }

  isVideoUrl(viewURL: string): boolean {
    const isVideoUrl = this.supportedVideoFormats.some(format => viewURL.includes(format));
    return isVideoUrl;
  }
}
