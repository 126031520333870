import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @Input() url: string;
  @Input() title: string;
  @Input() description: string;

  @Output() action: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  view() {
    this.action.emit('view');
  }
  comment() {
    this.action.emit('view');
  }
  delete() {
    this.action.emit('view');
  }

}
