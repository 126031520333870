import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as branchesMock from '../mock/branches-mock.json';
import { HttpClient } from '@angular/common/http';
import { Branch, BranchesData, BranchesSearchResponse } from '../data/branches';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiError, ApiResponse } from '../data/api.response';
@Injectable()
export class BranchesService extends BranchesData {

    branches: Branch[];

    constructor(private http: HttpClient) {
        super();
        this.branches = (branchesMock as any).default;
    }

    getBranches(pageNumber: number, pageSize: number, filterText: string): Observable<BranchesSearchResponse> {
        return this.http.get<ApiResponse>(`/Branch/AllBranches?pageNumber=${pageNumber}&pageSize=${pageSize}&f${filterText ? 'ilterText=' + filterText : ''}`).pipe(catchError(err => of(new ApiError([]))), switchMap(x => {
            return of(x.result as BranchesSearchResponse);
        }));
    }

    search(searchString: string): Observable<Branch[]> {
        // return of(this.branches.filter(v => v.name.toLowerCase().includes(searchString.toLowerCase())))
        return this.http.get<ApiResponse>(`/Branch/Branches/${searchString}`).pipe(catchError(err => of(new ApiError([]))), switchMap(x => {
            return of(x.result);
        }));
    }

    getBranchById(id: string | number): Observable<Branch> {
        return of(this.branches.find(p => p.id == id));
    }

}
