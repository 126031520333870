import { Component, OnInit, Input } from '@angular/core';
import { Job, JobsData, CommentsField } from '../../../@core/data/jobs';
import { AppMessage } from '../../../@core/utils/constants';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../../@theme/components';
import { SignalRService } from '../../../@core/services/SignalR.service';
import { Location } from '@angular/common';
import { AuthService } from '../../../@core/services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'ngx-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() CommentList: any;
  @Input() commentData: any;
  editing = false;
  Comment: FormControl;
  CommentsForm: FormGroup;



  constructor(private dialogService: NbDialogService,
    private formBuilder: FormBuilder, private signalR: SignalRService,private authService: AuthService, private location: Location) { }

  ngOnInit(): void {
    console.log(this.commentData);
    
    this.Comment = new FormControl('', Validators.required);

    this.CommentsForm = this.formBuilder.group({
      Comment: this.CommentList.comment

    })

   
  }
  isMyComment(comment: any) {
    if(!comment.user) return true;
    return comment.user.userUniqueID == this.userId;
  }

  get userId() {
    // console.log((this.authService.identityClaims['id']));
    return (this.authService.identityClaims['id']);

  }
  oneditclick(j) {
    this.editing = true;
  }

  delete() {
    // this.signalR.invokeDeleteComment(this.CommentList.id);
    // this.signalR.invokeComments(this.commentData,"Job");
  }

  update() {
    let Comment = this.CommentsForm.controls.Comment;

    let field = {
      CommentID: this.CommentList.id,
      JobID: this.commentData,
      Comment: Comment.value,
      CommentedBy: this.CommentList.commentedBy,
    }
      this.signalR.invokeEditComment(field);
      this.signalR.invokeComments(this.commentData,"Job");

      Comment.setValue('');
      this.signalR.invokeComments(this.commentData,"Job");
        this.signalR.subscribeToCommentsEvents(this.commentData);
  }




  cancel() {
    this.editing = false;
  }

}
