import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { LookupData, Role, RolePermissions } from '../data/lookup';
import { ApiError, ApiResponse } from '../data/api.response';
@Injectable()
export class LookupService extends LookupData {



    constructor(private http: HttpClient) {
        super();
    }

    getRoles(): Observable<Role[]> {
        return this.http.get<ApiResponse>('/Role/Roles').pipe(
            retry(3),
            catchError(err => of(new ApiError([]))), switchMap(x => {
                return of(x.result);
            }));
    }

    getRolePermissions(): Observable<RolePermissions[]> {
        return this.http.get<ApiResponse>('/Role/RolePermissions').pipe(
            retry(3),
            catchError(err => of(new ApiError([]))), switchMap(x => {
                return of(x.result);
            }));
    }


    getVesselLookups(): Observable<any> {
        return this.http.get<ApiResponse>('/Vessel/InitialData').pipe(
            retry(3),
            catchError(err => of(new ApiError([]))), switchMap(x => {
                return of(x.result);
            }));
    }

    getJobLookups(jobId: string): Observable<any> {
        return this.http.get<ApiResponse>('/JobSurvey/InitialData/' + (jobId ? jobId : "")).pipe(
            retry(3),
            catchError(err => of(new ApiError([]))), switchMap(x => {
                return of(x.result);
            }));
    }




}
