import { Observable } from "rxjs";

export interface Port {
    id: string;
    name: string;
    portCode: string;
    portID: number;
}

export interface PortSearchResponse {
    totalCount: number;
    items: Port[]
}

export abstract class PortsData {
    abstract getPorts(pageNumber: number, pageSize: number, filter: string): Observable<PortSearchResponse>;
    abstract search(searchString: string): Observable<Port[]>;
    abstract getPortById(id: string | number): Observable<Port>;
}