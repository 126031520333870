import { Component, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { NbThemeService, NbColorHelper } from '@nebular/theme';
import { HydrostaticData } from '../../../@core/data/hydrostatic';

@Component({
  selector: 'ngx-chartjs-line',
  template: `
    <chart type="line" [ngClass]="{'chartHeight': isTideChart}" [data]="chartData" [options]="options"></chart>
  `,
  styles: ['.chartHeight { height: 400px !important; padding-top: 20px; }']
})
export class ChartjsLineComponent implements OnDestroy {
  @Input() labels: Array<any>;
  @Input() data: Array<any>;
  @Input() label: String;
  @Input() lineColor: String;
  @Input() stepSize: any;
  @Input() beginAtZero: boolean;
  @Input() isTideChart: boolean;
  @Input() tideValuesArray: Array<any>
  options: any;
  themeSubscription: any;
  chartData: any;

  constructor(private theme: NbThemeService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("tideValuesArray : ",this.tideValuesArray)
    if (changes.data && this.data) {

      if (this.themeSubscription) {
        this.themeSubscription.unsubscribe();
      }

      this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

        const colors: any = config.variables;
        const chartjs: any = config.variables.chartjs;
        this.chartData = {
          labels: this.labels || [],
          datasets: this.tideValuesArray? this.tideValuesArray: [{
            data: this.data || [],
            label: this.label || "",
            backgroundColor: NbColorHelper.hexToRgbA(this.lineColor || colors.primary, 0.3),
            borderColor: this.lineColor,
            // pointBackgroundColor: this.labels.map(v => v < 400 ? "red" : "blue"),
          },
          ],
        };
        this.options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: true,
                  color: chartjs.axisLineColor,
                },
                ticks: {
                  fontColor: chartjs.textColor,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  color: chartjs.axisLineColor,
                },
                ticks: {
                  fontColor: chartjs.textColor,
                  stepSize: this.stepSize? this.stepSize: '',
                  beginAtZero: this.beginAtZero? this.beginAtZero: false,
                },
              },
            ],
          },
          legend: {
            labels: {
              fontColor: chartjs.textColor,
            },
          },
        };
      });
    }

  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }
}
