import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VesselService } from './vessels.service';
import { JobsService } from './jobs.service';
import { PortsService } from './ports.service';
import { BranchesService } from './branches.service';
import { LookupService } from './lookup.service';




const SERVICES = [
    VesselService,
    JobsService,
    PortsService,
    BranchesService,
    LookupService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        ...SERVICES,
    ],
})
export class DataModule {
    static forRoot(): ModuleWithProviders<DataModule> {
        return {
            ngModule: DataModule,
            providers: [
                ...SERVICES,
            ],
        };
    }
}
