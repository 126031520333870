import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss']
})
export class UploadTemplateComponent implements OnInit {

  files = new FormControl();
  constructor(private dialogRef: NbDialogRef<UploadTemplateComponent>) { }

  ngOnInit(): void {

    this.files.valueChanges.subscribe((data) => {
      
      
      if (data && data.length) {
        this.dialogRef.close(this.files.value);
      }
    });
  }

  close() {
    this.dialogRef.close(false);
  }


}
