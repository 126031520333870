import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDatetime'
})
export class LocalDatetimePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value && value.charAt(value.length - 1).toUpperCase() !== 'Z' ? value += 'Z' : value;
  }

}
