import { Component, OnInit , Input,EventEmitter, Output,} from '@angular/core';

@Component({
  selector: 'ngx-survey-calculate',
  templateUrl: './survey-calculate.component.html',
  styleUrls: ['./survey-calculate.component.scss']
})
export class SurveyCalculateComponent implements OnInit {
  @Input() surveyType: number;
  @Input() caption: string;
  @Input() value:string;
  @Input() isError:any;
  @Output() tabfocus: EventEmitter<any> = new EventEmitter<any>();
  filteredValue:boolean = true;
  empty = "";

  isWarning: boolean = false;
  filterdV: boolean = false;
  data: string;
  dotCheck: any;
  constructor() { }

  ngOnInit(): void {
  
    this.check();
    
    
    

   if(this.value == '0'){
     this.filterdV = true;
   }
   
  //  if(this.isError.fieldName == 'TPC' || this.isError.fieldName == 'LCF'){
  //         this.filteredValue = false;
  //  }
   
  }
  check(){
    ///[^.0-9]+/g
    this.dotCheck = String(this.value).match(/\./);
    //console.log(this.dotCheck[0]);
  }

  navigateToOriginalPage(){
     this.tabfocus.emit({'surveyType':this.surveyType,'caption':this.caption});
  }

}
