

export const environment = {
  production: false,
  apimSubscriptionKey: '3f9a1be68a844e96b4eee5709b1e5780',
  apiUrl: 'https://idraft-api-dev.intertek.com/idraft/api',
  // apiUrl: 'https://localhost:44356/api',
  jobSignalREndpoint: 'https://idraft-api-dev.intertek.com/idraft/JobManager',
  notificationUrl: 'https://idraft-api-dev.intertek.com/notification/api',
  firebase: {
    apiKey: "AIzaSyCpQeVh2qgR0pYGqFX8q4VELZ6HOwdmT8o",
    authDomain: "intertekdirectoryapp.firebaseapp.com",
    databaseURL: "https://intertekdirectoryapp.firebaseio.com",
    projectId: "intertekdirectoryapp",
    storageBucket: "intertekdirectoryapp.appspot.com",
    messagingSenderId: "986607191624",
    appId: "1:986607191624:web:741474e5eebaf45ba1fa7d"
  },
  oidc: {
    issuer: 'https://idraft-api-dev.intertek.com',
    clientId: "idraft-admin.app",
    scope: 'openid profile offline_access',
    responseType: 'code',
    clientSecret: '50ce4844-4ddc-49d9-8b1e-502709d18fba',
    timeOutFactor: 0.75,
    acr_values : {acr_values : "idp:AzureAD"}
  },
  enableLogrocket: true
};


// ====== QA environment ========
// export const environment = {
//   production: false,
//   apimSubscriptionKey: '3f9a1be68a844e96b4eee5709b1e5780',
//   //apiUrl: 'https://idraft.azurewebsites.net/api',
//   apiUrl: 'https://idraft-api-qa.intertek.com/idraft/api',
//   jobSignalREndpoint: 'https://idraft-api-qa.intertek.com/idraft/JobManager',
//   notificationUrl: 'https://idraft-api-qa.intertek.com/notification/api',
//   firebase: {
//     apiKey: "AIzaSyCpQeVh2qgR0pYGqFX8q4VELZ6HOwdmT8o",
//     authDomain: "intertekdirectoryapp.firebaseapp.com",
//     databaseURL: "https://intertekdirectoryapp.firebaseio.com",
//     projectId: "intertekdirectoryapp",
//     storageBucket: "intertekdirectoryapp.appspot.com",
//     messagingSenderId: "986607191624",
//     appId: "1:986607191624:web:741474e5eebaf45ba1fa7d"
//   },
//   oidc: {
//       issuer: 'https://idraft-api-qa.intertek.com',
//       clientId: "idraft-admin.app",
//       scope: 'openid profile offline_access',
//       responseType: 'code',
//       clientSecret: '50ce4844-4ddc-49d9-8b1e-502709d18fba',
//   }
// };
