<div class="page-heading">
    <a class="back" (click)="back()" *ngIf="link">Back</a>

    <!-- <nb-layout> -->
      <!-- <nb-layout-header> -->
        <!-- <nb-user name="Nikita Poltoratsky"
                 title="full-stack developer"
                 [nbContextMenu]="items"[nbContextMenu]="items"
                 nbContextMenuTag="my-context-menu">
        </nb-user> -->
      <!-- </nb-layout-header>
      <nb-layout-column class="colored-column-basic">Hello World!</nb-layout-column>
    </nb-layout> -->
    <!-- <button *ngIf="link" [nbContextMenu]="items" type="button" style="border: 0px; float: right; padding: 0rem !important; color: white; background-color: transparent;" >
     <i class="material-icons">more_vert</i>
    </button> -->

    <h2>{{heading}}</h2>
</div>
