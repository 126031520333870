import { Observable } from "rxjs";
import { ExcelImportResponse, HoldCapacityTableEntry, HydrostaticData, HydrostaticTableEntry, HydrostaticTableInput, TrimmingTableEntry } from "./hydrostatic";
import { Port } from "./ports";

export interface Vessel {
  // cargo: string;
  type: string;
  // agent: string;
  // to: string;
  hydrostaticDraftFromKeelTk: boolean;
  lightShip: number;
  summerDeadWeight: number;
  displacement: number;
  summerDraft: number;
  grt: number;
  lbp: number;
  keelThickness: number;
  port: Port;
  name: string;
  imoNo: string;
  id?: string;
  hydrostaticTables: HydrostaticTableEntry[];
  trimmingTables: TrimmingTableEntry[];
  maxHoldCapacity: HoldCapacityTableEntry[];
  hydrostaticDataLastUpdatedDate: Date,
  draftCorrectAft1: number,
  draftCorrectAft2: number,
  draftCorrectAft3: number,
  draftCorrectFwd1: number,
  draftCorrectFwd2: number,
  draftCorrectFwd3: number,
  draftCorrectMid1: number,
  draftCorrectMid2: number,
  draftCorrectMid3: number,
  summerDraftType: string,

  draftCorrectFwd1Remarks: string,
  draftCorrectFwd2Remarks: string,
  draftCorrectFwd3Remarks: string,
  draftCorrectMid1Remarks: string,
  draftCorrectMid2Remarks: string,
  draftCorrectMid3Remarks: string,
  draftCorrectAft1Remarks: string,
  draftCorrectAft2Remarks: string,
  draftCorrectAft3Remarks: string,
  // vessel: any;
  summerDisplacement: number;
  portOfRegistry: string;
}


export abstract class VesselData {
  abstract getVessels(): Observable<Vessel[]>;
  abstract importVesselHydrostaticFile(files: Array<File>, vesselId?: string, shouldSave?: boolean): Observable<ExcelImportResponse>;

  abstract createVessel(vesselData: any): Observable<Vessel>;
  abstract getVesselById(id: string | number): Observable<Vessel>;
  abstract updateVessel(vesselData: any, id: any): Observable<any>;
  abstract addvesselDraft(vesselData: any, id?: any): Observable<any>;
  abstract deleteVessel(vesselData: any): Observable<any>;
  abstract search(searchString: string): Observable<Vessel[]>;
  abstract saveBulkHydrostaticTableData(vesselDraftId: string, hydrostaticEntries: Array<HydrostaticTableInput>): Observable<HydrostaticTableEntry[]>;
  abstract getVersionsByVesselId(vesselDraftId: string): Observable<any>
  abstract downloadTemplate(vesselDraftId: string): Observable<any>;
  abstract downloadEmptyTemplate(): Observable<any>;
}