<div class="d-flex flex-fill justify-content-between align-items-center attachment">
    <img [src]="url" alt="" srcset="" class="attachment--preview mr-2">
    <div class="flex-fill text-hint">
        <h6 class="text-hint">{{title}}</h6>
        <span>{{description}}</span>
    </div>
    <div class="d-flex justify-content-end flex-fill">
        <nb-actions size="small">
            <nb-action class="control-item" icon="eye-outline" (click)="view()"></nb-action>
            <nb-action class="control-item" icon="message-circle-outline" (click)="comment()"></nb-action>
            <nb-action class="control-item" icon="trash-outline" (click)="delete()"></nb-action>

        </nb-actions>
    </div>
</div>