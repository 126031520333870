<!-- <p>pdf-viewer works!</p> -->
<div class="document">
    <pdf-viewer 
        [src]="pdfSrc"
        [original-size]="false"
        [render-text]="true"
        [fit-to-page]="false"
        [autoresize]="true"
        
        style="display: block; width: 48rem; height: 600px; position: absolute;bottom: 0; left: 50%; transform: translateX(-50%);"
    ></pdf-viewer>

</div>

<!-- <div class="message message--confirmation open">
    <div class="message__header">
        <h3 class="message__title">{{title}}</h3>
        <button type="button" class="btn btn--icon" (click)="dismiss()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="message__body message__body--confirmation">
        <pdf-viewer [src]="pdfSrc"
        [original-size]="false"
        [render-text]="true"
        [fit-to-page]="true"
        style="display: block; position: relative"
    ></pdf-viewer>
    </div>
</div> -->
