import { NgZone } from '@angular/core';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs'
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';



@Injectable({
  providedIn: 'root'
})
export class FirebaseService {


  currentMessage = new BehaviorSubject(null);
  messageSubscription: any;

  constructor(
    private angularFireMessaging: AngularFireMessaging, 
    private authService: AuthService, 
    private notificationService: NotificationService,
    private zone: NgZone,
    private router: Router) {

    this.requestPermission();
    this.receiveMessage();
    this.init();
  }

  init() {


        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                // this.messageSubscription = messaging;
                // messaging.onMessage = messaging.onMessage(messaging);
                // messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
                // messaging._next = (payload: any) => {
                    if (payload) {
                        this.currentMessage.next(payload);
                        console.log('notification payload:', payload);
                        let [notificationType, jobId] = payload.data.data.split('/');
                        const notify = new Notification(payload.notification.title, {
                            body: payload.notification.body,
                            icon: '',
                            data: jobId
                        });
                        notify.onclick = (event: any) => {
                            this.zone.run(() => {
                                this.router.navigate(['/pages/jobs/', jobId]);
                            });
                            notify.close();
                        };
                    }
                // };

            }
        );
        navigator.serviceWorker.addEventListener('message', (event) => {
            if(event.data.command == 'navigation' && event.data.jobId) {
                this.zone.run(() => {
                    const jobId = event.data.jobId;
                    this.router.navigate(['/pages/jobs/', jobId]);
                    
                });
            }
        });
    
}

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(token) {
    let claims: any = this.authService.identityClaims;
    let branchId = claims.BranchID;
    let roleIds: Array<string> = claims.RoleID instanceof Array ? claims.RoleID : [claims.RoleID];

    if (!claims) {
      return console.warn('user not logged in but rquesting firebase access');
    }
    // let notificationPayload = {
    //     referenceID1: claims.id,
    //     referenceID2: claims.RoleID && claims.RoleID instanceof Array ? claims.RoleID[0] : claims.RoleID,
    //     registrationToken: token,
    //     platform: platform,
    //     "channelKey": branchId 
    // }

    roleIds.forEach(roleId => { //TODO instead of repeating for each role id can we send an array?
      let notificationPayload = {
        referenceID1: claims.id,
        referenceID2: roleId,
        registrationToken: token,
        platform: "web",
        "channelKey": branchId
      }
      this.notificationService.registerDevice(notificationPayload).subscribe((data) => {
        console.log(data);
      }, (e) => {
        console.error(e);
      });
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    console.log("recieve messages");
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }

}
