import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as portsMock from './../mock/ports-mock.json';
import { HttpClient } from '@angular/common/http';
import { Port, PortsData, PortSearchResponse } from '../data/ports';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiError, ApiResponse } from '../data/api.response';
@Injectable()
export class PortsService extends PortsData {

    ports: Port[];

    constructor(private http: HttpClient) {
        super();
        this.ports = (portsMock as any).default;
    }

    getPorts(pageNumber: number, pageSize: number, filterText: string): Observable<PortSearchResponse> {
        return this.http.get<ApiResponse>(`/Port/AllPorts?pageNumber=${pageNumber}&pageSize=${pageSize}${filterText ? '&filterText=' + filterText : ''}`).pipe(catchError(err => of(new ApiError([]))), switchMap(x => {
            return of(x.result as PortSearchResponse);
        }));
    }

    search(searchString: string): Observable<Port[]> {
        // return of(this.ports.filter(v => v.name.toLowerCase().includes(searchString.toLowerCase())))
        return this.http.get<ApiResponse>(`/Port/Ports/${searchString}`).pipe(catchError(err => of(new ApiError([]))), switchMap(x => {
            return of(x.result as Port[]);
        }));
    }

    getPortById(id: string | number): Observable<Port> {
        return of(this.ports.find(p => p.id == id));
    }

    

}
