import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Job, JobsData } from '../../../@core/data/jobs';
@Component({
  selector: 'ngx-view-photo',
  templateUrl: './view-photo.component.html',
  styleUrls: ['./view-photo.component.scss']
})
export class ViewPhotoComponent implements OnInit {
  jobattachments:Array<Job>;


  @Input() Jobattach: any;
  image: boolean;
  video: boolean;
  constructor(private dialogRef: NbDialogRef<ViewPhotoComponent>, private jobData: JobsData) { }

  ngOnInit(): void {
    console.log("this.Jobattach : ",this.Jobattach);

    this.isImage(this.Jobattach);
  }

  isImage(Jobattach) {

    let url = new URL(Jobattach.viewURL).pathname;

    if (url.match(/\.(jpeg|jpg|gif|png|jfif|PNG)$/) != null) {
      this.image = true;
      return true;
    }
    else if (url.match(/\.(mp4)$/) != null) {
      this.video = false;
      return false;
    }
  }

  ok() {
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }
}
