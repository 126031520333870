import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { addCommentFields, Replycomments,CommentsField } from '../../../@core/data/jobs';
import { SignalRService } from '../../../@core/services/SignalR.service';
import { AuthService } from '../../../@core/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-reply-comment',
  templateUrl: './reply-comment.component.html',
  styleUrls: ['./reply-comment.component.scss']
})
export class ReplyCommentComponent implements OnInit {
  @Input()  replyComment: any;
  @Input() commentData:any;
  editing = false;
  replytext:any;
  jobid: string;
  subscriptions: Subscription[] = [];
  CommentList: CommentsField[];
  commentsForm: FormGroup;
  textareaReply: FormControl;
  showReply = true;
  constructor(private formBuilder: FormBuilder,private dialogRef: NbDialogRef<ReplyCommentComponent>,private route: ActivatedRoute, private authService: AuthService, private signalR: SignalRService) { }


  ngOnInit(): void {
    this.textareaReply = new FormControl('');
    this.commentsForm = this.formBuilder.group({
      // textarea: this.textarea,
      textareaReply: this.textareaReply,

    })
    
  }

  cancel() {
    this.editing = false;
  }
  onreplyclick(data) {
    
      this.editing = true;
    
    
  
  }
  getComments() {
    const subscription: Subscription = this.signalR.getCommentslist()
      .subscribe((fieldsList: Array<CommentsField>) => {
        this.CommentList = fieldsList;
        console.log(this.CommentList);
      });
    this.subscriptions.push(subscription);
  }

  replycomment(data) {
    if (this.textareaReply.value) {
      const field: Replycomments = {
        jobID: this.commentData,
        comment: this.textareaReply.value,
        commentedBy: data.commentedBy,
        CommentID: data.id
      }
      this.signalR.invokeReplyComment(field);
      this.signalR.invokeComments(this.commentData,"Job");
      //this.commentsForm.reset();
      this.signalR.invokeComments(this.commentData,"Job");
       this.signalR.subscribeToCommentsEvents(this.commentData);
       //this.getComments();
       this.editing = false;
    }
    else {
      alert('Enter Comment')
    }

  }

}
