import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, HostListener, Inject  } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/services/auth.service';
import { NotificationService } from '../../../@core/services/notification.service';
import { FirebaseService } from '../../../@core/services/firebase.service';
import { environment } from '../../../../environments/environment';
import { NbMenuItem, NB_WINDOW } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../../@theme/components';
import { AppMessage } from '../../../@core/utils/constants';
import { Profile, User, UserData } from '../../../@core/data/users';
import { ProfileComponent } from '../../../pages/users/profile/profile.component';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  host: {
    "(window:click)": "onClick()"
  }
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showNotifications: boolean = false;
  notificationCount$: Observable<any>;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'intertek',
      name: 'Intertek',
    }
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile', }, { title: 'Log out' }];
  isMenuOpen: boolean = true;
  setNumber: any;
  public screenWidth: any;
  public screenHeight: any;

  public userProfile: Profile;
  public userName: string;
  menuItems = [
    { title: 'Profile' },
    { title: 'Logout' },
  ];

  currentUrl: string
  claims: any

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private nbMenuService: NbMenuService,
    private notificationService: NotificationService,
    private firebaseService: FirebaseService,
    private router: Router,
    private dialogService: NbDialogService,
    private userData: UserData,
    @Inject(NB_WINDOW) private window,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  ngOnInit() {
    // if(!this.router.url.includes('report')) {
    this.currentUrl= this.router.url
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    // this.authService.onTokenChange()
    //   .subscribe((token: NbAuthJWTToken) => {
    //     if (token.isValid()) {
    //       this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
    //       console.log(this.user);
    //     }
    //   });

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == "Log out") {
          // this.router.navigate(['auth', 'logout']);
          this.authService.logout();
        }
      });

      this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'more-items'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == "Jobs") {
          this.router.navigate(['pages', 'jobs']);
        } else if (title == "Vessels") {
          this.router.navigate(['pages', 'vessels']);
        } else if (title == "Clients") {
          this.router.navigate(['pages', 'client-master']);
        } else if (title == "Admin") {
          this.router.navigate(['pages', 'admin']);
        }
      });


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.notificationCount$ = this.notificationService.notifcations$;
    this.claims= this.authService.identityClaims
    if(!this.claims !== null) {
      this.userData.getUserProfile().subscribe(res =>{
        this.userProfile= res;
        this.userName= this.userProfile.firstName + ' ' + this.userProfile.lastName
      })
    }
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if(title === 'Profile' ){
          this.editUserProfile()
        } else {
          this.onLogout();
        }
      });

    // }

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  toggleNotifications(event) {
    if(event && event.stopPropagation)
      event.stopPropagation();
    this.showNotifications = !this.showNotifications;
    this.setNumber = false;
  }

  setCount($event){
    console.log($event);
    this.setNumber = $event;
  }

  onClick() {
  this.showNotifications = false;
  }
  // .replace('${name}')
  onLogout() {
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: AppMessage.LOGOUT_CONFIRM_TITLE,
        description: AppMessage.LOGOUT_CONFIRM_MESSAGE,
      }
    }).onClose.subscribe(result => {
      if(result) {
        this.authService.logout();
      }
    });
  }
  // user: User
  editUserProfile() {
    let userProfile= this.userProfile
    this.dialogService.open(ProfileComponent, {
      context: {
        userProfile
      }
    }).onClose.pipe(filter(shouldRefresh => shouldRefresh)).subscribe(_ => {
        this.userData.getUserProfile().subscribe(res =>{
        this.userProfile= res;
      })
    });
  }

  isTestEnvironment() {
    return !environment.production;
  }

  items: NbMenuItem[] = [
    {
      title: 'Jobs',
    },
    {
      title: 'Vessels'
    },
    {
      title: 'Clients'
    },
    {
      title: 'Admin'
    }
  ];
}
