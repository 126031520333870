import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { SignalRService } from '../../../@core/services/SignalR.service';
import { AuthService } from '../../../@core/services/auth.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommentsCount, JobsData } from '../../../@core/data/jobs';

@Component({
  selector: 'ngx-photos-comments',
  templateUrl: './photos-comments.component.html',
  styleUrls: ['./photos-comments.component.scss']
})
export class PhotosCommentsComponent implements OnInit, OnDestroy {
  @Input() JOBID;
  @Input() attachmentData;
  @Input() allAttachmentIds;
  attachmentComments: any;
  jobid: any;
  textarea: FormControl;
  textareaReply: FormControl;
  commentsForm: FormGroup;
  commentdata: any;
  subscriptions: Subscription[] = [];
  jobidData: any;
  editing = false;
  show = true;
  showReply :any;
  index: any;
  replyIndex: any;
  Comment: FormControl;
  editIndex: any;
  sComment: any;
  makeEdit = false;
  updateData: any;
  // allAttachmentIds: string[];
  commentsCountList: any[];
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: NbDialogRef<PhotosCommentsComponent>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private signalR: SignalRService,
    private jobsData: JobsData

  ) { }

  ngOnInit(): void {
    this.textarea = new FormControl('');
    this.textareaReply = new FormControl('');
    this.Comment = new FormControl('');

    this.commentsForm = this.formBuilder.group({
      textarea: this.textarea,
      textareaReply: this.textareaReply,
      Comment : [],
    })


    //this.getComments()
    this.jobid = this.JOBID;
    this.attachmentData;
    this.signalR.isHubConnected$.subscribe(connected => {
      if (!connected) {
        this.signalR.signalRConnection();
      }
      else {

        this.getComments();
       this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      //  this.signalR.subscribeToAttachmentCommentsEvents(this.attachmentData.id);
       this.signalR.invokeUnreadAttachmentComment(this.userId, this.allAttachmentIds);
       this.getCommentsCount();

      }
    })
  }

  getComments() {
    const subscription: Subscription = this.signalR.getAttachmentCommentslist()
      .subscribe((fieldsList) => {
        this.attachmentComments = fieldsList;
        console.log(this.attachmentComments);
      //  this.getAttachmentIds();
      });
    this.subscriptions.push(subscription);
  }


  // getAttachmentIds() {
  //   this.allAttachmentIds = this.attachmentComments.map(attachment => attachment.id);
  //   console.log('all attachment ids:', this.allAttachmentIds);
  // }

  getCommentsCount() {
    const commentsCountSub: Subscription = this.signalR.getUnreadAttachmentCommentsCount()
    .subscribe((countList: Array<CommentsCount>) => {
      this.commentsCountList = countList;
      console.log(this.commentsCountList);

    });
    this.subscriptions.push(commentsCountSub);
  }

  isMyComment(comment: any) {
    if(!comment.user) return true;
    return comment.user.userUniqueID == this.userId;
  }

  onreplyclick(i) {
    this.replyIndex = i;
    this.editing = true;
    this.makeEdit = false;
  }

  oneditclick(j,cmnt) {
    this.sComment = cmnt;
    this.editIndex = j;
    this.editing = true;
    this.commentsForm.patchValue({
      Comment: this.sComment,
    });
  }

  delete(deleteData) {
    let isAttch = true;
    const field = {
      jobID: this.jobid,
      CommentID:deleteData.id,
      IsAttachment : true,
    }
    this.signalR.invokeAttachmentDeleteComment(field);
    this.getComments();
       this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      //  this.signalR.subscribeToAttachmentCommentsEvents(this.attachmentData.id);
       this.signalR.invokeUnreadAttachmentComment(this.userId, this.allAttachmentIds);
       this.getCommentsCount();
       this.makeEdit= false
    // this.commentsForm.patchValue({
    //   Comment: deleteData.comment,
    // });
    // this.signalR.invokeAttachmentComments(this.attachmentData.id,"Attachment");
  }

  oneditclick1(data){
    this.makeEdit = true;
    this.updateData = data;
    this.commentsForm.patchValue({
      Comment: data.comment,
    });
    this.editing = false;
    this.replyIndex = -1;
  }

  cancel(j) {
    this.editing = false;
    this.editIndex = -1;
    this.makeEdit = false;
  }
  cancelReply(i){
    this.editing = false;
    this.replyIndex = -1;
  }

  replycomment(data) {
    if (this.textareaReply.value) {
      const field = {
        jobID: this.jobid,
        comment: this.textareaReply.value,
        commentedBy: this.userId,
        CommentID: data.id,
        AttachmentID : this.attachmentData.id,
        IsAttachment : true,
      }
      this.signalR.invokeAttachmentReplyComment(field);
      this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      this.commentsForm.reset();
      this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      //  this.signalR.subscribeToAttachmentCommentsEvents(this.jobid);
      this.getComments();
       this.editing = false;
       this.replyIndex = -1;
    }
    else {
      alert('Enter Comment')
    }
  }

  addComment() {
    console.log(this.textarea);
    if (this.textarea.value) {
      const field = {
        jobID: this.jobid,
        comment: this.textarea.value,
        commentedBy: this.userId,
        AttachmentID : this.attachmentData.id,
        IsAttachment : true,
      }

      this.signalR.invokeAddComment(field);
      this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      this.commentsForm.reset();
      this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      //  this.signalR.subscribeToAttachmentCommentsEvents(this.jobid);
       this.getComments();
    }
    else {
      alert('Enter Comment')
    }
  }



  update() {
    let Comment = this.commentsForm.controls.Comment;
    this.updateData;
    let field = {
      CommentID: this.updateData.id,
      JobID: this.jobid,
      Comment: Comment.value,
      CommentedBy: this.userId,
      AttachmentID : this.attachmentData.id,
      IsAttachment : true,
    }
      this.signalR.invokeAttachmentEditComment(field);
      this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      this.commentsForm.reset();
      this.signalR.invokeattachmentComments(this.attachmentData.id,"Attachment");
      //  this.signalR.subscribeToAttachmentCommentsEvents(this.jobid);
       this.getComments();
       this.editing = false;
       this.editIndex = -1;
       this.makeEdit = false;
  }

  get userId() {
    return (this.authService.identityClaims['id']);
  }

  close(){
    this.dialogRef.close(true);
  }

  resetCommentsCount() {
    let commentIds: Array<string> = [];
    this.attachmentComments.forEach(comment => {
      if(comment.subComment.length > 0) {
        comment.subComment.forEach(subComment => commentIds.push(subComment.id))
      };
      commentIds.push(comment.id);
    });
    console.log(commentIds);


    this.jobsData.resetComments(this.attachmentData.id,commentIds).subscribe(result => {
      // this.signalR.unreadAttachmentComments$.next([]);
      this.signalR.invokeUnreadAttachmentComment(this.userId, this.allAttachmentIds);
          if (result) {
            console.log(result);
            //this.attachments = result.jobAttachments;
          }
        }, error => {
          //this.toastrService.danger(error?.error?.message, AppMessage.DELETE_JOB_FAILURE,);
        });
  }

  ngOnDestroy() {
    this.resetCommentsCount();
  }

}
