

        <div *ngIf="isImage == true">
            <img width="160" height="106" src='{{srcURL}}'>
        </div>

        <div *ngIf="isImage == false">
            <video width="160" height="106" controls>
                <source src='{{srcURL}}' type="video/mp4">
            </video>
        </div>

