import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit {

@Input() field;
@Input() formType;
@Output() redirect: EventEmitter<true> = new EventEmitter();
  constructor(private dialogRef: NbDialogRef<ShowErrorsComponent>,private router: Router) { }

  ngOnInit(): void {
  }

  isInitialForm() {
    return this.formType == 'initial';
  }

  isFinalForm() {
    return this.formType == 'final';
  }
  close(data:string) {
    this.dialogRef.close(data);
  }
  close1() {
    this.dialogRef.close();
  }

  goToInitialForm(field: string) {
    this.close(field);
  }

  goToFinalForm(field: string) {
    this.close(field);
  }

}
