import { Observable } from "rxjs";
import { ExcelImportResponse, HydrostaticData, HydrostaticTableEntry, TrimmingTableEntry } from "./hydrostatic";
import { Port } from "./ports";

export interface VesselMaster {
    id: string,
    name: string,
    imoNo: string, 
    type: string, 
    flag: string, 
    class: string, 
    sourceSystem: string 
}
export class Surveyvessel implements VesselMaster {
    id: string;
    name: string;
    imoNo: string; 
    type: string;
    flag: string; 
    class: string; 
    sourceSystem: string;
    
    constructor(formData: any) {
        this.id = formData.vessel;
        this.name = formData.jobOrder;
        this.imoNo = formData.branch;
        this.type = formData.vessel;
        this.flag = formData.jobOrder;
        this.class = formData.branch;
        this.sourceSystem = formData.jobOrder;
        
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            imoNo: this.imoNo,
            type: this.type,
            flag: this.flag,
            class: this.class,
            sourceSystem: this.sourceSystem,
            
            //Othe properties which needs to be sent back to server. Survey readings are omitted since signalR will be used
        };
    }
}


export abstract class VesselMasterData {
    abstract getVessels(): Observable<VesselMaster[]>;
    // abstract importVesselHydrostaticFile(vesselId: string, files: Array<File>, shouldSave?: boolean): Observable<ExcelImportResponse>;
     abstract createVessel(vesselData: any): Observable<VesselMaster>;
     abstract getVesselById(id: string | number): Observable<VesselMaster>;
     abstract updateVessel(vesselData: any): Observable<any>;
     abstract deleteVessel(vesselData: any): Observable<any>;
     abstract search(searchString: string): Observable<VesselMaster[]>;
}