import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Notification } from '../../../@core/data/notification';
import { FirebaseService } from '../../../@core/services/firebase.service';
import { NotificationService } from '../../../@core/services/notification.service';

@Component({
  selector: 'ngx-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  @Input() visible;
  @Output() readNotification: EventEmitter<Notification> = new EventEmitter();
  @Output() minusOne: EventEmitter<true> = new EventEmitter();
  notifcations$: Observable<Notification[]>;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  showNotifications: boolean = true;
  //minusOne: boolean;

  constructor(private notificationService: NotificationService,) { 
    
  }

  ngOnInit(): void {
    this.notifcations$ = this.notificationService.notifcations$;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  close(notification) {
    this.notificationService.markAsRead(notification);
    this.readNotification.emit(notification);
    this.minusOne.emit(true);
  }
 

  resolveIcon(type) {

    switch (type) {
      case 'Comment':
        return 'message-circle';
      case 'Rejected':
        return 'close-circle';
      case 'Waiting for Approval':
        return 'checkmark-circle';
      case 'Completed':
      case 'In-progress':
      case 'Approved':
        return 'checkmark-circle';
    }
  }

}
