import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() title: string;
  @Input() pdfSrc: any;

  
  // pdfSrc = "https://idraftpreprodstorageacc.blob.core.windows.net/idraft-files/Intertek-iDraft-Channel/GHHARMONYDS_SURVEYCERTIFICATE_BHP-AU200-010-10-0023-21-Sug12021-10-06/20211006T092943687.pdf?sv=2018-03-28&sr=b&sig=v6pA0UuYdGpwY5p%2BS%2F9GLJNfTYl2jm65Jmx3tXGmcMQ%3D&se=2021-10-13T12%3A14%3A15Z&sp=r&rscd=attachment%3B%20filename%3DGHHARMONYDS_SURVEYCERTIFICATE_BHP-AU200-010-10-0023-21-Sug12021-10-06%2F20211006T092943687.pdf"


  constructor(private dialogRef: NbDialogRef<PdfViewerComponent>) { }

  ngOnInit(): void {

  }

  dismiss(){
    this.dialogRef.close();
  }
  // display: block; width: 600px; height: 600px; position: absolute; left: 50%; transform: translateX(-50%);
  // style="display: flex; justify-content: center; align-items: center; max-width: 600px; max-height: 600px;"
}
