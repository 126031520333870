<form [formGroup]="attachmentForm" enctype="multipart/form-data">
  <div class="message open" id="modalEditPhoto">
    <div class="message__header">
      <h3 class="message__title">{{modalTitle}}</h3>
      <button type="button" class="btn btn--icon" (click)="close()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="message__body">
      <div class="loader-container" *ngIf="showLoading$ | async; else showForm">
        <div class="loader"></div>
      </div>
      <div class="upload-failed" *ngIf="errorMessage">
        <i class="material-icons">alert</i>
        <h3>{{errorMessage}}. Please Try Again!</h3>
      </div>
      <div class="upload-success" *ngIf="uploadedFileResponse">
        <div *ngIf="jobAttachment else addSuccess">
          <i class="material-icons">checkmark-circle</i>
          <h1>Attachment Uploaded succefully!!</h1>
        </div>

        <ng-template #addSuccess>
          <i class="material-icons">checkmark-circle></i>
          <h1>Attachment Uploaded succefully!!</h1>
        </ng-template>
      </div>

      <ng-template #showForm>
        <div *ngIf="fileUploadSuccess === false">
          <div class="image-container" >
            <span *ngIf="fileSrc">
              <img *ngIf="isPhoto && !isPhotoURL" [src]="fileSrc" width="100%" height="100%" alt="survey-image/video" />
              <video *ngIf="isVideo && !isVideoURL" [src]="fileSrc" width="100%" height="100%" alt="survey-image/video"
                controls></video>
            </span>
            <span *ngIf="jobAttachment">
              <img *ngIf="isPhotoURL" [src]="jobAttachment.viewURL" width="100%" height="100%" alt="survey-image/video" />
              <video *ngIf="isVideoURL" [src]="jobAttachment.viewURL" width="100%" height="100%" alt="survey-image/video"
                controls></video>
            </span>
          </div>

          <div class="custom-input">
            <label>Replace Attachment With</label>
            <input type="file" accept="image/*,video/*" (change)="handleImage($event)" class="input-field-bg" formControlName="File">
          </div>

          <div class="form-group">
            <label for="photoTitle" class="form-group__label">
               Title
            </label>
            <input type="text" class="form-group__input" id="photoTitle" placeholder="Enter a Title"
              formControlName="Title"
              [value]="selectedOption != '' ? selectedOption : jobAttachment.title ? jobAttachment.title: ''"

              >
              <div *ngIf="formControls.Title.touched && formControls.Title.errors" class="invalid">
                <label
                  *ngIf="formControls.Title.errors.required"
                  class="text-danger"
                > Title is required </label>
              </div>
          </div>

          <div class="form-group">
            <label for="photoDescription" class="form-group__label">
               Description
            </label>
            <textarea class="form-group__input textarea--short" id="photoDescription"
            placeholder="Enter photo description"
              formControlName="Description"
              [value]=" description ? description : '' "
              (click)="onDescriptionChange()">
            </textarea>
         </div>

          <!-- <div class="custom-input">
            <label>Description</label> <br />..
            <textarea

              class="input-field-bg"
            ></textarea>
            <div *ngIf="formControls.Description.touched && formControls.Description.errors" class="invalid">
              <label
                *ngIf="formControls.Description.errors.required"
                class="text-danger"
                > Description is required </label>
            </div>
          </div> -->
        </div>
      </ng-template>
    </div>

    <div class="message__actions message__actions--form">
      <button type="button" class="btn btn--secondary btn--inline" (click)="onDismis()">CANCEL</button>
      <button type="button" [disabled]="attachmentForm.invalid" class="btn btn--primary btn--inline" (click)="onSubmit()">SAVE CHANGES</button>
    </div>

  </div>
</form>

