import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { ApiError, ApiResponse } from '../data/api.response';
//import { VesselMaster, VesselMasterData } from '../data/vesselMaster';
import { client, clientData } from '../data/clients';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }

  getClients(): Observable<client[]> {
    return this.http.get<ApiResponse>('/Client/Clients').pipe(retry(3), switchMap(x => {
        return of(x.result as client[]);
    }));
}

CreateClients(clientdata: any): Observable<client> {
  return this.http.post<ApiResponse>('/Client/AddClient', clientdata).pipe(retry(3), switchMap(x => {
      return of(x.result as client);
  }));
}

search(searchString: string): Observable<client[]> {
  return this.http.get<ApiResponse>(`/Client/Clients/${searchString}`).pipe(retry(3), catchError(err => of(new ApiError([]))), switchMap(x => {
      return of(x.result as client[]);
  }));
}

getclientById(id: string | number): Observable<client> {
  // return of(this.vessels.find(v => v.imoNo == id));
  return this.http.get<ApiResponse>(`/Client/ClientByID/${id}`).pipe(retry(3), switchMap(x => {
      return of(x.result as client);
  }));
}

updateClients(clientdata: any): Observable<client> {
  return this.http.put<ApiResponse>(`/Client/UpdateClient/${clientdata.id}`, clientdata).pipe(retry(3), switchMap(x => {
      return of(x.result as client);
  }));
}

deleteClients(clientdata: any): Observable<any> {
  return this.http.delete<ApiResponse>(`/Client/DeleteClient/${clientdata}`,).pipe(retry(3), switchMap(x => {
      return of(x.result as client);
  }));
}

}
