import { Observable } from "rxjs";

export interface Branch {

    id: string,
    name: string,
    city: string,
    state: string,
    countryCode: string,
    regionCode: string,
    latitude: number,
    longitude: number,
    hasLab: boolean,
    branchCode: string,

}

export interface BranchesSearchResponse {
    totalCount: number;
    items: Branch[]
}

export abstract class BranchesData {
    abstract getBranches(pageNumber: number, pageSize: number, filterText: string): Observable<BranchesSearchResponse>;
    abstract search(searchString: string): Observable<Branch[]>;
    abstract getBranchById(id: string | number): Observable<Branch>;
}