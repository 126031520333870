import { Observable } from "rxjs";

export interface client {

    clientCode: string,
    clientBusinessName: string,
    email: string,
    groupEmail: string,
    customerPhoneNumber: string,
    isCertificateFormatRequired: boolean,
    stowagePlanReportRequired: boolean
  
}

export abstract class clientData {
    abstract getClients(): Observable<client[]>;
    abstract CreateClients(clientdata:any): Observable<client[]>;
    abstract search(searchString: string): Observable<client[]>;
    abstract getclientById(id: string | number): Observable<client>;
    abstract updateClients(clientdata:any): Observable<client>;
    abstract deleteClients(clientdata:any): Observable<client>;

}