import { Directive, ElementRef, HostListener, Input, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[portName]'
})

export class PortNameDirective {
@Input('portName') key:any;
    constructor(private model: NgControl, protected hostRef: ElementRef,) { }
    ngOnInit() {

        // if (this.model.control.value && this.model.control.value.portCode) {
        //     this.hostRef.nativeElement.value = this.model.control.value.name;
        // }

        // this.model.control.valueChanges.subscribe((e) => {
        //     if (this.model.control.value && this.model.control.value.portCode) {
        //         this.hostRef.nativeElement.value = this.model.control.value.name;
        //     }
        // });

    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.key){
            //this.hostRef.nativeElement.value = this.model.control.value.name;
           //console.log();
            // portCode
           if (this.model.control.value && this.model.control.value.name) {
            this.hostRef.nativeElement.value = this.model.control.value.name;
        }

        this.model.control.valueChanges.subscribe((e) => {
            if (this.model.control.value && this.model.control.value.name) {
                this.hostRef.nativeElement.value = this.model.control.value.name;
            }
        });
           
    }
      }

}
