
<div class="message open" id="viewPhoto" style="overflow-y: scroll; height: 35rem;">
    <div class="message__header">
          <h3 class="message__title">{{Jobattach.title}}</h3>
          <button type="button" class="btn btn--icon" (click)="close()">
             <i class="material-icons">close</i>
          </button>
    </div>

    <div class="message__photo" *ngIf="image == true">
          <img src="{{Jobattach.viewURL}}">
    </div>
    <div class="message__photo" *ngIf="video == false">
        <video width="320" height="240" controls>
            <source src='{{Jobattach.viewURL}}' type="video/mp4">
        </video>
  </div>
    <div class="message__text message__text--readonly">
          
       <div class="form-group">
          <label for="photoTitle" class="form-group__label">
             Title
          </label>
          <input type="text" class="form-group__input" id="photoTitle" readonly="" value="{{Jobattach.title}}">
       </div>

       <div class="form-group">
          <label for="photoDescription" class="form-group__label">
             Description
          </label>
          <textarea class="form-group__input textarea--short" id="photoDescription" readonly="">{{Jobattach.description}}
          </textarea>
       </div>
       <div class="form-group">
          <label for="photoAddedBy" class="form-group__label">
          Added by
          </label>
          <textarea class="form-group__input textarea--short" id="photoAddedBy" readonly="">{{Jobattach.userCreatedBy.firstName}}
         </textarea>
          <!-- <input type="text" class="form-group__input" id="photoAddedBy" readonly="">{{Jobattach.userCreatedBy.firstName}} -->
       </div>

    </div>
    <div class="message__actions">
          <!-- <button type="button" class="btn btn--secondary" data-action="message">Edit...</button> -->
          <button type="button" class="btn btn--primary" (click)="close()">Close</button>
    </div>
 </div>
