import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { addCommentFields, Replycomments,CommentsField, JobsData } from '../../../@core/data/jobs';
import { SignalRService } from '../../../@core/services/SignalR.service';
import { AuthService } from '../../../@core/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-new-comment',
  templateUrl: './new-comment.component.html',
  styleUrls: ['./new-comment.component.scss']
})
export class NewCommentComponent implements OnInit {
  @ViewChild('scroll', { static: true }) scroll: any;

  @Input() data;
  textarea: FormControl;
  textareaReply: FormControl;
  commentsForm: FormGroup;
  commentdata: any;
  jobid: any;
  subscriptions: Subscription[] = [];
  CommentList: Array<any>;
  replyComments:Replycomments[];
  jobidData: any;
  editing = false;
  show = true;
  showReply :any;
  index: any;
  replyIndex: any;
  Comment: FormControl;
  editIndex: any;
  sComment: any;
  makeEdit = false;
  updateData: any;
  constructor(private formBuilder: FormBuilder,private jobsData: JobsData,private dialogRef: NbDialogRef<NewCommentComponent>,private route: ActivatedRoute,
    private authService: AuthService, private signalR: SignalRService) { }

  ngOnInit(): void {
    this.ngOnChange();
    this.data;
    this.textarea = new FormControl('');
    this.textareaReply = new FormControl('');

    this.Comment = new FormControl('');


    this.commentsForm = this.formBuilder.group({
      textarea: this.textarea,
      textareaReply: this.textareaReply,
      Comment : [],

    })

    this.jobid = this.data;
    this.getComments()

    this.signalR.isHubConnected$.subscribe(connected => {
      if (!connected) {
        this.signalR.signalRConnection();
      }
      else {
        //this.getComments();
        this.signalR.invokeComments(this.jobid,"Job");
        this.signalR.subscribeToCommentsEvents(this.jobid);
        // this.signalR.subscribeToAttachmentCommentsEvents(this.jobid);
        this.getComments();
      }
    })

  }

  ressetCommentsCount(){
    let commentIds: Array<string> = [];
    this.CommentList.forEach(comment => {
      if(comment.subComment.length > 0) {
        comment.subComment.forEach(subComment => commentIds.push(subComment.id))
      };
      commentIds.push(comment.id);
    });
    console.log(commentIds);


    this.jobsData.resetComments(this.jobid,commentIds).subscribe(result => {
      this.signalR.unreadJobComments$.next(0);
          if (result) {
            console.log(result);
            //this.attachments = result.jobAttachments;
          }
        }, error => {
          //this.toastrService.danger(error?.error?.message, AppMessage.DELETE_JOB_FAILURE,);
        });
  }

  ngOnChange(){
    this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
  }


  isMyComment(comment: any) {
    if(!comment.user) return true;
    return comment.user.userUniqueID == this.userId;
  }

  onreplyclick(i) {
    this.replyIndex = i;
    this.editing = true;
    this.makeEdit = false;
  }

  oneditclick(j,cmnt) {
    this.sComment = cmnt;
    this.editIndex = j;
    this.editing = true;
    this.commentsForm.patchValue({
      Comment: this.sComment,
    });
  }

  oneditclick1(data){
    this.makeEdit = true;
    this.updateData = data;
    this.commentsForm.patchValue({
      Comment: data.comment,
    });
    this.editing = false;
    this.replyIndex = -1;
  }

  cancel() {
    this.editing = false;
    this.editIndex = -1;
  }
  cancelReply(i){
    this.editing = false;
    this.replyIndex = -1;
  }

  getComments() {
    const subscription: Subscription = this.signalR.getCommentslist()
      .subscribe((fieldsList: Array<CommentsField>) => {
        this.CommentList = fieldsList;
        console.log(this.CommentList);

      });
    this.subscriptions.push(subscription);
    this.ressetCommentsCount();
    this.ngOnChange();
  }

  get userId() {
    return (this.authService.identityClaims['id']);

  }

  replycomment(data) {
    if (this.textareaReply.value) {
      const field: Replycomments = {
        jobID: this.jobid,
        comment: this.textareaReply.value,
        commentedBy: this.userId,
        CommentID: data.id
      }
      this.signalR.invokeReplyComment(field);
      this.signalR.invokeComments(this.jobid,"Job");
      this.commentsForm.reset();
      this.signalR.invokeComments(this.jobid,"Job");
       this.signalR.subscribeToCommentsEvents(this.jobid);
       this.getComments();
       this.editing = false;
       this.replyIndex = -1;
    }
    else {
      alert('Enter Comment')
    }

  }

  addComment() {
    console.log(this.textarea);
    //console.log(this.data);

    if (this.textarea.value) {
      const field: addCommentFields = {
        jobID: this.jobid,
        comment: this.textarea.value,
        commentedBy: this.userId
      }

      this.signalR.invokeAddComment(field);
      this.signalR.invokeComments(this.jobid,"Job");
      this.commentsForm.reset();
      this.signalR.invokeComments(this.jobid,"Job");
       this.signalR.subscribeToCommentsEvents(this.jobid);
       this.ngOnChange();
       this.getComments();


    }
    else {
      alert('Enter Comment')
    }

  }

  close(){
    this.show = false;
    this.dialogRef.close(true);
  }

  //sub comments


  delete(deleteData) {
    const field = {
      JobID: this.jobid,
      CommentID: deleteData.id,
    }
    this.signalR.invokeDeleteComment(field);
    this.signalR.invokeComments(this.jobid,"Job");
  }

  update() {
    let Comment = this.commentsForm.controls.Comment;
    this.updateData;
    let field = {
      CommentID: this.updateData.id,
      JobID: this.jobid,
      Comment: Comment.value,
      CommentedBy: this.userId,
    }
      this.signalR.invokeEditComment(field);
      this.signalR.invokeComments(this.jobid,"Job");
      this.commentsForm.reset();
      this.signalR.invokeComments(this.jobid,"Job");
       this.signalR.subscribeToCommentsEvents(this.jobid);
       this.getComments();
       this.editing = false;
       this.editIndex = -1;
       this.makeEdit = false;
  }

}
