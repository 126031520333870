<div class="list-alerts alert-dropdown" [class.open]="visible" id="dropdownAlert">
    <ul class="alerrts-container" *ngIf="(notifcations$|async)?.length;else noData">
        <li class="alert alert--approved" *ngFor="let notification of notifcations$|async"
            [routerLink]="[notification.action]" [queryParams]="{action:notification.icon}"
            (click)="close(notification)"  >
            <a>
                <nb-icon [icon]="resolveIcon(notification.icon)"></nb-icon>
                <div class="alert__desc">
                    {{notification.message}}
                </div>
                <nb-icon icon="chevron-right"></nb-icon>
            </a>
        </li>
        <!-- <li class="alert alert--commented">
            <a>
                <nb-icon icon="message-circle"></nb-icon>
                <div class="alert__desc">
                    Surveyor commented on
                    <span class="nowrap">Job xxxxxxxx</span>
                </div>
                <nb-icon icon="chevron-right"></nb-icon>
            </a>
        </li> -->
    </ul>
    <ng-template #noData>

        <span>
            There are no notifications
        </span>

    </ng-template>
</div>