import { Observable } from "rxjs";

export interface Role {
    id: string;
    name: string;
}

export interface RolePermissions {
    roleID: string,
    role: string,
    rolePermissions: Array<Permissions>
}

export interface Permissions {
    rolePermissionID: number,
    permission: string,
    description: string,
    isEnabled?: boolean,
}

export abstract class LookupData {
    abstract getRoles(): Observable<Role[]>;
    abstract getRolePermissions(): Observable<RolePermissions[]>;
    abstract getVesselLookups(): Observable<any>;
    abstract getJobLookups(jobId: string): Observable<any>;
}