<form [formGroup]="userForm" (submit)="updateUser()" *ngIf="userForm">
  <div class="message open" id="modalEditUser">
    <div class="message__header">
      <h3 class="message__title">Edit User Profile</h3>
      <button type="button" class="btn btn--icon" (click)="close()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="message__body">
      <div class="form-group__container">
        <div class="form-group">
          <label for="newUserFirstName" class="form-group__label required"> First Name </label>
          <input type="text" class="form-group__input" id="newUserFirstName" formControlName="firstname">
          <p style="color:red" *ngIf="!userForm.controls.firstname.valid && submitted">First Name Required</p>
        </div>
        <div class="form-group">
          <label for="newUserLastName" class="form-group__label required"> Last Name </label>
          <input type="text" class="form-group__input" id="newUserLastName" formControlName="lastname">
          <p style="color:red" *ngIf="!userForm.controls.lastname.valid && submitted">Last Name Required</p>
        </div>
      </div>
      <div class="form-group__container">
        <div class="form-group">
            <label for="newUserEmail" class="form-group__label required"> Email </label>
            <input type="text" readonly class="form-group__input" id="newUserEmail" formControlName="email">
            <p style="color:red" *ngIf="!userForm.controls.email.valid && submitted">Email Required</p>
        </div>
        <div class="form-group">
            <label for="newUserPhone" class="form-group__label required"> Phone Number </label>
            <input [type]="'number'" readonly class="form-group__input" id="newUserPhone" formControlName="phoneNumber">
            <p style="color:red" *ngIf="!userForm.controls.phoneNumber.valid && submitted">Only Number Required</p>
        </div>
    </div>
      <div class="form-group__container">
        <div class="form-group">
          <label for="newUserBranch" class="form-group__label required"> Branch </label>
          <input type="text" class="form-group__input" id="newUserBranch" formControlName="branch"
              [nbAutocomplete]="branchAutocompleter" autocomplete="off">
              <p style="color:red" *ngIf="!userForm.controls.branch.valid && submitted">Branch Required</p>

          <nb-autocomplete #branchAutocompleter [handleDisplayFn]="getNameAndCode" [activeFirst]="true">
              <nb-option *ngFor="let branch of filteredBranches$ | async" [value]="branch">
                  {{ branch.name }} ({{branch.branchCode}})
              </nb-option>
          </nb-autocomplete>
        </div>
        <div class="form-group">
          <label for="newUserQualifications" class="form-group__label">
              Qualifications
          </label>
          <input type="text" class="form-group__input" id="newUserQualifications"
            formControlName="qualifications">
        </div>
      </div>

      <div class="form-group__container">
        <div class="form-group">
          <label for="userSignature" class="form-group__label">
            Signature
          </label>
          <div id="surveyChiefOfficerSignature" class="signature">
            <signature-pad *ngIf="retrievedSurveyorSignature === ''" #surveyorSignature  [options]="{
              'minWidth': 1,
              'canvasHeight': 150,
              'canvasWidth': '350'
              }"
              (onEndEvent)="captureSurveyorSignature()"
              (dragenter)="onDragEnter($event)"
              (dragover)="onDragOver($event)"
            (drop)="onDrop($event)">
            </signature-pad>
            <img class="uploadSignature" *ngIf="retrievedSurveyorSignature !== ''" src='{{retrievedSurveyorSignature}}' />
          </div>
          <button type="button" class="btn clear-sign" (click)="clearSurveyorSignature(fileInput)">
            Clear
          </button>
        </div>
        <div class="form-group" style="margin-left: 60px; margin-top: 40px;">
          <div class="center">
            <b>OR</b>
          </div>
          <div class="signatureFileRightAlign">
            <input #fileInput style="padding-left: 50px;
              padding-top: 0px;" type="file" (change)="uploadSignature($event)" accept="image/*">
          </div>
        </div>
      </div>
    </div>
    <div class="message__actions message__actions--form">
      <button type="submit" class="btn btn--primary btn--inline" [disabled]="buttonDisable && !userForm.controls.valid">
          Save Changes
      </button>
      <button type="button" class="btn btn--secondary btn--inline" (click)="close()">
          Cancel
      </button>
    </div>
  </div>
</form>
