<div class="message message--confirmation open" id="modalDeleteClient">
    <div class="message__header">
        <h3 class="message__title">{{title}}</h3>
        <button type="button" class="btn btn--icon" (click)="close()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="message__body message__body--confirmation">
        <p>
            <i class="material-icons">help</i>
        </p>
        <p>{{description}}</p>
    </div>

    <div class="message__actions message__actions--form">
        <button type="button" class="btn btn--secondary btn--inline" (click)="close()">{{cancelButtonText}}
        </button>
        <button type="button" class="btn btn--primary btn--inline" (click)="ok()">{{okButtonText}}</button>
    </div>
</div>