import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-vessel-name',
  templateUrl: './vessel-name.component.html',
  styleUrls: ['./vessel-name.component.scss']
})
export class VesselNameComponent implements OnInit {
  @Input() vesselName: string;
  @Input() imoNumber: string;


  constructor() { }

  ngOnInit(): void {
  }

}
