import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'ngx-video-photo',
  templateUrl: './video-photo.component.html',
  styleUrls: ['./video-photo.component.scss']
})
export class VideoPhotoComponent implements OnInit {
  @Input() isImage:boolean;

  @Input() srcURL:string;
  constructor() { }

  ngOnInit(): void {
    console.log(this.isImage);
    console.log(this.srcURL);
    
  }

}
