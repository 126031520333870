
export enum AppMessage {
    CONFIRM_DELETE = 'Yes,Delete',
    CANCEL_DELETE = 'Don\'t Delete',
    REMOVE_VESSEL_CONFIRM_TITLE = "Delete Vessel?",
    REMOVE_VESSEL_CONFIRM_MESSAGE = "Are you sure you want to delete vessel ${name}? ",
    USER_ADD_SUCCESS = 'User added successfully.',
    USER_ADD_FAILURE = 'Error while adding user.',
    EDIT_USER_FAILURE = "Error while updating user.",
    EDIT_USER_SUCCESS = "User updated successfully.",
    REMOVE_USER_CONFIRM_TITLE = "Delete User?",
    REMOVE_USER_CONFIRM_MESSAGE = "Are you sure you want to delete user ${name}?",
    REMOVE_CLIENT_CONFIRM_TITLE = "Delete Client?",
    REMOVE_CLIENT_CONFIRM_MESSAGE = "Are you sure you want to delete Client ${name}?",
    CLIENT_ADD_SUCCESS = 'Client added successfully.',
    CLIENT_UPDATE_SUCCESS = 'Client updated successfully.',
    CLIENT_UPDATE_FAILURE = 'Error while updating client.',
    DELETE_USER_SUCCESS = "User deleted successfully.",
    DELETE_CLIENT_SUCCESS = "Client deleted successfully.",
    DELETE_USER_FAILURE = "Error while deleting user.",
    DELETE_CLIENT_FAILURE = "Error while deleting client.",
    VESSEL_ADD_SUCCESS = 'Vessel added successfully.',
    VESSEL_ADD_FAILURE = 'Error while adding vessel.',
    VESSEL_UPDATE_SUCCESS = 'Vessel updated successfully.',
    VESSEL_UPDATE_FAILURE = 'Error while updating vessel.',
    VESSEL_REMOVE_SUCCESS = 'Vessel removed successfully.',
    VESSEL_REMOVE_FAILURE = 'Error while removing vessel.',
    VESSEL_IMPORT_SUCCESS = 'Vessel details imported successfully.',
    VESSEL_IMPORT_FAILURE = 'Error while importing vessel details.',
    JOB_CREATION_SUCCESS = "Job added successfully.",
    SURVEY_START_SUCCESS = "Survey started successfully.",
    JOB_CREATION_FAILURE = "Error while creating the job.",
    SURVEY_START_FAILURE = "Error while starting the survey.",
    JOB_UPDATION_SUCCESS = "Job updated successfully",
    JOB_UPDATION_FAILURE = "Error while updating job details.",
    REMOVE_JOB_CONFIRM_TITLE = "Remove Job?",
    REMOVE_JOB_CONFIRM_MESSAGE = "Are you sure you want to delete this job?",
    DELETE_JOB_SUCCESS = "Job removed successfully.",
    DELETE_JOB_FAILURE = "Error while removed the job.",
    APPROVE_JOB_SUCCESS = "Job approval successful.",
    APPROVE_JOB_FAILURE = "Error! Job approval failed.",
    APPROVAL_STATUS_JOB_CONFIRM_TITLE = "Approve Job?",
    APPROVE_JOB_CONFIRM_MESSAGE = "Are you sure you want to approve this job?",
    REJECT_JOB_SUCCESS = "Job rejected successfully.",
    REJECT_JOB_FAILURE = "Job could not be rejected.Please try again.",
    REJECT_JOB_CONFIRM_MESSAGE = "Are you sure you want to reject this job?",
    SURVEY_CALC_GET_FAILURE = "Error in getting survey claculations.",
    FILL_REQUIRED_FIELDS = 'Fill in required fields',
    TRIMMING_ERROR_TITLE = 'There was an error while trimming',
    TRIMMING_SAVE_SUCCESS = 'Trim details saved successfully.',
    AUDIT_HISTORY_GET_FAILURE = "Error in getting audit trail logs.",
    JOB_UNLOCK_SUCCESS = "Unlocked all fields successfully.",
    JOB_UNLOCK_FAILURE = "Couldn't unlock the fields.",
    SEND_REPORT_FAILURE = "Couldn't send reports.",
    SEND_REPORT_SUCCESS = "Successfully sent reports.",
    GENERATE_REPORT_SUCCESS = "Successfully generated reports.",
    GENERATE_REPORT_FAILURE = "Couldn't generate reports.",
    HYDROSTATIC_TABLE_UPDATE_SUCCESS = "Hydrostatic table updated successfully",
    REMOVE_HYDROSTATIC_ENTRY_CONFIRM_TITLE = "Delete Hydrostatic Entry?",
    REMOVE_HYDROSTATIC_ENTRY_CONFIRM_MESSAGE = "Are you sure you want to delete the entry for Draft reading ${entry}?",
    HYDROSTATIC_TABLE_REQUIRED_FIELDS = "Please enter value in all the fields",
    REMOVE_ATTACHMENT_CONFIRM_TITLE = "Delete Attachment?",
    REMOVE_ATTACHMENT_CONFIRM_MESSAGE = "Are you sure you want to delete attachment ${name}?",
    DELETE_ATTACHMENT_SUCCESS = "Attachment deleted successfully.",
    LOGOUT_CONFIRM_TITLE= "Logout?",
    LOGOUT_CONFIRM_MESSAGE= "Are you sure you want to Logout?",
    JOB_APPROVAL_SUCCESS= 'Job is sent for Approval.',
    EXPORT_CONFIRM_TITLE= "Please select the sheet you want to export",
    DELETE_HCC_REPORTS= "Delete HCC Reports?",
    DELETE_HCC_REPORTS_CONFIRM_MESSAGE = "Are you sure you want to delete HCC reports? ",
    DELETE_HCC_SUCCESS = "HCC reports removed successfully.",
    DELETE_OTHER_DOCUMENT= "Delete Other Document Reports?",
    DELETE_OTHER_DOCUMENT_CONFIRM_MESSAGE = "Are you sure you want to delete Other Document? ",
    DELETE_OTHER_DOCUMENT_SUCCESS = "Other Document removed successfully.",
}

export enum ButtonText {
    YES = "Yes",
    NO = "No",
    CANCEL = "Cancel",
    SUBMIT = "Submit",
}

export enum Roles {
    DATA_ADMIN = 'Admin',
    MASTER_MARINER = 'MasterMariner',
    SURVEYOR= 'Surveyor',
    INSPECTOR = 'Inspector',
}

export enum JobStates {

    NOT_STARTED = 'Not Started',
    STARTEDD = 'Started',
    INITIAL_DRAFT_SUBMITTED = 'initial Draft Submitted',
    INITIAL_DRAFT_APPROVED = 'initial Draft Approved',
    FINAL_DRAFT_SUBMITTED = 'fianl Draft Submitted',
    FINAL_DRAFT_APPROVED = 'fianl Draft Approved',
    COMPLETED = 'Completed'
}

export enum JobStatus {
    OPEN = 'Open',
    IN_PROGRESS = 'In-progress',
    WAITING_FOR_APPROVAL = 'Waiting for Approval',
    APPROVED = 'Approved',
    COMPLETED = 'Completed'
}

export const DefaultRoutes = {
    [Roles.MASTER_MARINER]: '/pages/jobs',
    [Roles.DATA_ADMIN]: '/pages/admin',
    [Roles.SURVEYOR]: '/pages/jobs',
    [Roles.INSPECTOR]: '/404',
}

export const camelize = (text) => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return text.substr(0, 1).toLowerCase() + text.substr(1);
}


export enum STORAGE_KEY {
    SELECTED_JOB_STATUS = 'selectedJobStatus',
    NUMBER_OF_RECORDS_TO_DISPLAY = 'recordsToDisplay',
}

export const NOTIFICATION_REFRESH_INTERVAL = 1 * 60 * 1000;//in minutes * seconds * milliseconds


export enum PAGE_TITLES {
    VESSEL_LIST = "Vessels: iDraft",
    ADD_VESSEL = "New Vessel: iDraft",
    EDIT_VESSEL = "Edit vessel: iDraft",
    JOBS_LIST = "Jobs: iDraft",
    VIEW_JOB = "View Job: iDraft",
    CLIENT_LIST = "Clients: iDraft",
    USER_LIST = "Users: iDraft",
    PORT_LIST = "Ports: iDraft",
    BRANCH_LIST = "Branches: iDraft",
    PREVIEW_REPORT= "Preview: iDraft",
    TIDAL_DATA= "Tidal Data"
}

export enum FILE_ACTIONS {
  VIEW = 'view',
  DOWNLOAD = 'download'
}

export const SUPPORTED_PHOTO_FORMATS = [
  "JPEG", "jpeg", "JPG", "jpg", "PNG", "png"
];

export const SUPPORTED_VIDEO_FORMATS = [
  "MP4", "mp4", "MKV", "mkv", "MOV", "mov"
];

export enum APP_MESSAGES {
  FILE_NOT_SUPPORTED = "File type is not supported",
  FILE_TOO_LARGE = "File Cannot be larger than 100 Mb",
}

export enum REPORT_TYPE {
  INTERIM_REPORT = "Interim Report",
  FINAL_REPORT = "Final Report",
  VESSEL_HATCH= "VesselOrHatchInspection",
  HCC= "Hold Cleanliness Certificate",
  DRAFT_REPORT= "Draft survey",
  DEADFREIGHT_REPORT= "Dead Freight",
  OTHER_DOCUMENT= "OtherDocuments"
}


