import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Vessel, VesselData } from '../data/vessels';
import { ExcelImportResponse, HydrostaticTableEntry, HydrostaticTableInput } from '../data/hydrostatic';
import { HttpClient } from '@angular/common/http';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { ApiError, ApiResponse } from '../data/api.response';
@Injectable()
export class VesselService extends VesselData {

    constructor(private http: HttpClient) {
        super();
    }

    getVessels(): Observable<Vessel[]> {
        // console.log("gettling vessels");api/Vessel/AllVesselDrafts
        // return of(this.vessels);
        return this.http.get<ApiResponse>('/Vessel/AllVesselDrafts').pipe(retry(3), switchMap(x => {
            return of(x.result as Vessel[]);
        }));
    }

    // importVesselHydrostaticFile(vesselId: String, files: File[], shouldSave: boolean = false): Observable<ExcelImportResponse> {
    //     const formData = new FormData();
    //     formData.append('', files[0]);
    //     return this.http.post<ExcelImportResponse>(`/Vessel/UploadTemplate?isSave=${shouldSave}${vesselId ? '&vesselID=' + vesselId : ''}`, formData).pipe(retry(3))
    //     // return of((hydrostaticMock as any).default);
    // }
    importVesselHydrostaticFile(files: File[], vesselId: String = null, shouldSave: boolean = false): Observable<ExcelImportResponse> {
        const formData = new FormData();
        formData.append('', files[0]);
        return this.http.post<ExcelImportResponse>(`/Vessel/UploadTemplate?isSave=${shouldSave}${vesselId ? '&vesselDraftID=' + vesselId : ''}`, formData).pipe(retry(3), switchMap(x => {
            return of(x as ExcelImportResponse);
        }));
    }
    // importVesselHydrostaticFileS(vesselId: String, files: File[], shouldSave: boolean): Observable<ExcelImportResponse> {
    //     const formData = new FormData();
    //     formData.append('', files[0]);
    //     return this.http.post<ExcelImportResponse>(`/Vessel/UploadTemplate?vesselId=${vesselId}&isSave=${shouldSave}`, formData).pipe(retry(3), switchMap(x => {
    //         return of(x as ExcelImportResponse);
    //     }));
    // }

    createVessel(vesselData: any): Observable<Vessel> {
        return this.http.post<ApiResponse>('/Vessel/AddVesselDraft', vesselData).pipe(retry(3), switchMap(x => {
            return of(x.result as Vessel);
        }));
    }

    addvesselDraft(vesselData: any, id?: any): Observable<any> {
        if (id) {
            return this.updateVessel(vesselData, id);
        }
        return this.http.post<ApiResponse>('/Vessel/AddVesselDraft', vesselData).pipe(retry(3), switchMap(x => {
            return of(x.result as any);
        }));
    }



    getVesselById(id: string | number): Observable<Vessel> {
        // return of(this.vessels.find(v => v.imoNo == id));
        return this.http.get<ApiResponse>(`/Vessel/VesselDraftByID/${id}`).pipe(retry(3), switchMap(x => {
            return of(x.result as Vessel);
        }));
    }

    updateVessel(vesselData: any, id: any): Observable<any> {
        return this.http.put<ApiResponse>(`/Vessel/UpdateVesselDraft/${id}`, vesselData).pipe(retry(3), switchMap(x => {
            return of(x.result as Vessel);
        }));
    }

    deleteVessel(vesselData: any): Observable<any> {
        return this.http.delete<ApiResponse>(`/Vessel/DeleteVesselDraft/${vesselData.id}`,).pipe(retry(3), switchMap(x => {
            return of(x.result as Vessel);
        }));
    }

    search(searchString: string): Observable<Vessel[]> {
        return this.http.get<ApiResponse>(`/Vessel/VesselDrafts/${searchString}`).pipe(retry(3), catchError(err => of(new ApiError([]))), switchMap(x => {
            return of(x.result as Vessel[]);
        }));
    }

    saveBulkHydrostaticTableData(vesselDraftId: string, hydrostaticEntries: HydrostaticTableInput[]): Observable<HydrostaticTableEntry[]> {
        return this.http.post<ApiResponse>(`/Vessel/SaveBulkHydrostaticTableData/${vesselDraftId}`, hydrostaticEntries).pipe(retry(1), switchMap(x => {
            return of(x.result as HydrostaticTableEntry[]);
        }));
    }

    getVersionsByVesselId(vesselDraftId: string): Observable<any> {
        return this.http.get<ApiResponse>(`/Vessel/VersionDetails/${vesselDraftId}`,).pipe(retry(3), switchMap(x => {
            return of(x.result as any);
        }));
    }

    downloadTemplate(vesselDraftId: string): Observable<any> {
        return this.http.get(`/Vessel/DownloadTemplate?vesselDraftID=${vesselDraftId}`, {responseType: 'blob',});
    }

    downloadEmptyTemplate(): Observable<any> {
        return this.http.get(`/Vessel/DownloadTemplate`, {responseType: 'blob',});
    }
}
