import { of as observableOf, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Profile, User, UserData } from '../data/users';
import { Roles } from '../utils/constants';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../data/api.response';
import { catchError, retry, switchMap } from 'rxjs/operators';

@Injectable()
export class UserService extends UserData {

  constructor(private http: HttpClient) {
    super();
  }

  getUsers(): Observable<User[]> {
    // return observableOf(this.users);
    return this.http.get<ApiResponse>('/User/Users').pipe(retry(3), switchMap(x => of(x.result)));
  }
  addUser(user: User): Observable<User> {
    return this.http.post<ApiResponse>('/User/AddUser', user).pipe(switchMap(x => {
      return of(x.result);
    }));
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<ApiResponse>(`/User/UpdateUser/${user.id}`, user).pipe(switchMap(x => {
      return of(x.result);
    }));
  }

  getUserById(id: string): Observable<User> {
    return this.http.get<ApiResponse>(`/User/UserByID/${id}`).pipe(retry(3), switchMap(x => {
      return of(x.result);
    }));
  }

  deleteUser(user: User): Observable<User> {
    return this.http.delete<ApiResponse>(`/User/DeleteUser/${user.id}`).pipe(switchMap(x => {
      return of(x.result);
    }));
  }

  getUserProfile(): Observable<Profile>{
    return this.http.get<ApiResponse>('/User/userProfile').pipe(switchMap(x=>{
      return of(x.result);
    }))
  }

  updateUserProfile(profile :Profile):Observable<Profile>{
    return this.http.put<ApiResponse>('/User/UpdateUserprofile', profile).pipe(switchMap(x=> {
      return of(x.result)
    }))
  }
}
