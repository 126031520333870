import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Job, JobsData, SurveyJob } from '../data/jobs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../data/api.response';
import { retry, switchMap } from 'rxjs/operators';
import { getFileNameFromHeader } from '../utils/fileUtils';
import { FILE_ACTIONS } from '../utils/constants';
import { ExcelImportResponse } from '../data/hydrostatic';

@Injectable()
export class JobsService extends JobsData {






    constructor(private http: HttpClient) {
        super();
    }


    getJobs(): Observable<Job[]> {
        return this.http.get<ApiResponse>('/JobSurvey/Jobs?showAll=true').pipe(retry(3), switchMap(x => {
            return of(x.result as Job[]);
        }));
    }

    createJob(jobData: SurveyJob): Observable<Job> {
        return this.http.post<ApiResponse>('/JobSurvey/AddJob', jobData.toJson()).pipe(retry(3), switchMap(x => {
            return of(x as any);
        }));
    }
    getJobById(id: string | number): Observable<Job> {
        return this.http.get<ApiResponse>(`/JobSurvey/JobByID/${id}`).pipe(retry(3), switchMap(x => {
            return of(x.result as Job);
        }));
    }
    getinitialDataJobById(id: string): Observable<Job> {
        return this.http.get<ApiResponse>(`/JobSurvey/InitialData/${id}`).pipe(retry(3), switchMap(x => {
            return of(x.result as Job);
        }));
    }
    updateJob(jobData: SurveyJob): Observable<Job> {
        return this.http.put<ApiResponse>(`/JobSurvey/UpdateJob/${jobData.id}`, jobData.toJson()).pipe(retry(3), switchMap(x => {
            return of(x.result as Job);
        }));
    }
    deleteJob(jobData: any): Observable<Job> {
        return this.http.delete<ApiResponse>(`/JobSurvey/DeleteJob/${jobData.id}`,).pipe(retry(3), switchMap(x => {
            return of(x.result as Job);
        }));
    }

    getUniqueJobID(): Observable<string> {
        return this.http.get<ApiResponse>(`/JobSurvey/GetNewJobId `,).pipe(retry(3), switchMap(x => {
            return of(x.result as string);
        }));
    }

    getclientsNames(): Observable<string> {
        return this.http.get<ApiResponse>(`/Client/Clients`,).pipe(retry(3), switchMap(x => {
            return of(x.result as string);
        }));
    }

    search(searchString: string): Observable<any> {
        return this.http.get<ApiResponse>(`/Client/Clients/${searchString}`,).pipe(retry(3), switchMap(x => {
            return of(x.result as string);
        }));
    }


    approveJob(id: string | number, status: boolean): Observable<boolean> {
        return this.http.put<ApiResponse>(`/JobSurvey/ApproveJob/${id}/${status}`, true).pipe(retry(3), switchMap(x => {
            return of(x.result as boolean);
        }));
    }

    calculateSurveyDetails(id: string) {
        return this.http.put<ApiResponse>(`/JobSurvey/CalculateSurveyDetails/${id}`, true).pipe(retry(3));
    }

    getJobsByStatus(status: string): Observable<Job[]> {
        return this.http.get<ApiResponse>(`/JobSurvey/JobsByStatus`, {
            params: {
                showAll: 'false',
                status
            }
        }).pipe(retry(3), switchMap(x => {
            return of(x.result as Job[]);
        }));
    }

    calculateTrim(trimInput: any, trimType: string): Observable<any> {
        return this.http.post<ApiResponse>('/jobSurvey/ShowTrimmingReport/' + trimType, trimInput).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }
    calculateManualTrim2(trimInput: any, lcgTrimming: boolean = false): Observable<any> {
      return this.http.post<ApiResponse>('/jobSurvey/showTrimmingReport2/' + lcgTrimming, trimInput).pipe(retry(3), switchMap(x => {
          return of(x.result);
      }));
  }
    saveTrim(trimData: any, trimType: String): Observable<any> {
        return this.http.post<ApiResponse>('/jobSurvey/saveTrimmingReport/' + trimType, trimData).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }

    getTrimmingDataForJob(jobId: string, lcgTrimming: boolean = false): Observable<any> {
        return this.http.get<ApiResponse>('/jobSurvey/getTrimmingDataForJob/' + jobId ).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }

    checkForHogSag(jobID: string, surveyType: string) {
        return this.http.get('/jobSurvey/CheckForHogSag', {
        params: { jobID, surveyType }
        });
    }

    getAuditHistory(jobID: string): Observable<any> {
        return this.http.get<ApiResponse>(`/jobSurvey/AuditLogDetailsForJob/${jobID}`,).pipe(retry(3), switchMap(x => {
            return of(x.result as string);
        }));
    }

    getStowageDataForJob(id: String): Observable<any> {
        return this.http.get<ApiResponse>('/jobSurvey/GetStowageDataForJob/' + id).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }
    generateStowagePlan(jobId: string, stowagePlan: any): Observable<any> {
        return this.http.post<ApiResponse>('/jobSurvey/GenerateStowagePlan/' + jobId, stowagePlan).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }
    saveStowagePlan(stowagePlan: any): Observable<any> {
        return this.http.post<ApiResponse>('/jobSurvey/SaveStowagePlan', stowagePlan).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }

    generateStowagePlanReport(jobID: string): Observable<any> {
        return this.http.get(`/JobSurvey/GenerateStowagePlanReport/${jobID}`,
            { responseType: 'blob', observe: 'response' }
        ).pipe(retry(3), switchMap(res => {


            return of(
                {
                    content: res.body,
                    fileName: getFileNameFromHeader(res.headers)
                }
            );
        }));
    }

    getStowageReportUrls(jobID: string): Observable<any> {
        return this.http.get<ApiResponse>(`/JobSurvey/GenerateStowagePlanReport/${jobID}`).pipe(retry(3), switchMap(res => {
            return of(res.result);
        }));
    }

    startSurvey(id: string): Observable<any> {
        return this.http.put<ApiResponse>('/jobSurvey/StartJob/' + id, { jobID: id }).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }

    unlockJobFields(jobID: string): Observable<any> {
        return this.http.delete<ApiResponse>('/jobSurvey/UnlockFields/' + jobID).pipe(retry(3));
    }

    GenerateSurveyReport(jobID: string): Observable<any> {
      let payload= {
        "jobID": jobID,
      }
        return this.http.post<ApiResponse>(`/JobSurvey/GenerateSurveyReport/${jobID}`, payload,).pipe(retry(3), switchMap(res => {
            return of(res.result);
        }));
    }

    GenerateDeadFreightReport(jobID: string): Observable<any> { 
      let payload= {
        "jobID": jobID,
      }
        return this.http.post<ApiResponse>(`/JobSurvey/GenerateDeadFreightReport/${jobID}`, payload,).pipe(retry(3), switchMap(res => {
            return of(res.result);
        }));
    }

    GenerateIntermediateReport(jobID: string, chiefOfficerSignature: string): Observable<any> {
      let signature= {
        "chiefOfficerSignature": chiefOfficerSignature
      }
      return this.http.post<ApiResponse>(`/JobSurvey/GenerateIntermediateReport/${jobID}`, signature,).pipe(retry(3), switchMap(res => {
        return of(res.result);
    }));
    }

    viewSurveyReport(jobID: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        let payload= {
          "jobID": jobID,
          // "isDeadFreight": isDeadFreightReport
        }
        return this.http.post(`/JobSurvey/GenerateSurveyReport/${jobID}`,payload, {
          headers,
          observe: 'response',
          responseType: 'blob'
        }).pipe(retry(1), switchMap(res => {
          return of({ content: res.body, fileName: getFileNameFromHeader(res.headers) })
        }));
        
        
    }

    resetComments(id:string,referenceID:string[]){
        return this.http.post<ApiResponse>(`/Comment/SetReadComments/${id}`,referenceID).pipe(retry(3), switchMap(res => {
            return of(res.result);
        }));
    }


    sendReportToClient(jobID: string, emails: Array<string> = []) {
        return this.http.post<ApiResponse>('/JobSurvey/SendGeneratedReport/' + jobID, emails).pipe(retry(3), switchMap(x => {
            return of(x as any);
        }));
    }

    sendHccReportToClient(jobID: string,boardingDateTime: string, remarks: any, submittedDateTime: any, emailIDs: Array<string> = []){
      return this.http.post<ApiResponse>('/JobSurvey/SendGeneratedHoldCleanlinessCertificate/' + jobID, {
        "boardingDateTime": boardingDateTime,
        "hccRemarks": remarks,
        "submittedDateTime": submittedDateTime,
        emailIDs
      }).pipe(retry(3), switchMap(x => {
        return of(x as any);
      }));
    }

    generateTrimReport(jobID: string, trimType: string): Observable<any> {
        return this.http.get(`/JobSurvey/GenerateTrimSurveyReport/${jobID}/${trimType}`, { responseType: 'blob', observe: 'response' }).pipe(retry(3), switchMap(res => {


            return of(
                {
                    content: res.body,
                    fileName: getFileNameFromHeader(res.headers)
                }
            );
        }));
    }

    getReportIds(jobID: string, isDeadFreightReport: boolean): Observable<any> {
      let payload= {
        "jobID": jobID,
        "isDeadFreight": isDeadFreightReport
      }
        return this.http.post<ApiResponse>(`/JobSurvey/ReportViewUrls?jobID=${jobID}`,payload).pipe(retry(3), switchMap(res => {
            return of(res.result);
        }));
    }

    getportsName(portId: string): Observable<any> {
        return this.http.get<ApiResponse>(`/Port/GetPort/${portId}`,).pipe(retry(3), switchMap(x => {
            return of(x.result);
        }));
    }


    getAllports(): Observable<any> {
      return this.http.get<ApiResponse>('/Port/AllPorts',).pipe(retry(3), switchMap(x => {
        return of(x.result);
      }));
    }

    GenerateHoldCleanlinessCertificate(jobID: string, action: string, boardingDateTime: any, remarks: any, submittedDateTime: any): Observable<any> {
      let payload = {'boardingDateTime': boardingDateTime,
                    'hccRemarks': remarks,
                    'submittedDateTime': submittedDateTime,
                    'action': action
                  }
      if(action === FILE_ACTIONS.VIEW) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.post<Blob>(`/JobSurvey/GenerateHoldCleanlinessCertificate/${jobID}`, payload, {
            headers,
            observe: 'response',
            responseType: 'blob' as 'json',
        }).pipe(retry(1));
      } else {
        return this.http.post<ApiResponse>(`/JobSurvey/GenerateHoldCleanlinessCertificate/${jobID}`, payload).pipe(retry(3),
        switchMap(res => {
          return of(res.result);
        }));
      }
    }

    GenerateVesselOrHatchInspection(jobID: string, formData: any) {
      return this.http.post<ApiResponse>(`/JobSurvey/GenerateVesselOrHatchInspection/${jobID}`, formData).pipe(retry(3), switchMap(x => {
        return of(x as any);
      }));
    }

    GenerateOtherDocuments(jobID: string, formData: any) {
      return this.http.post<ApiResponse>(`/JobSurvey/GenerateOtherDocuments/${jobID}`, formData).pipe(retry(3), switchMap(x => {
        return of(x as any);
      }));
    }

    OtherDocuments(jobID: string, type: any) {
      let payload= {
        jobID: jobID,
        type: type
      }
      return this.http.post<ApiResponse>(`/JobSurvey/OtherDocuments/`, payload).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }
    
    DeleteAllOtherDocuments(jobID: string) {
      return this.http.delete<ApiResponse>(`/JobSurvey/DeleteAllOtherDocuments/${jobID}`).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    DeleteOtherDocument(jobID: string, fileId: string) {
      let payload= {
        jobID: jobID,
        fileId: fileId
      }
      return this.http.post<ApiResponse>(`/JobSurvey/DeleteOtherDocument/`,payload).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }
    // HccSavedReports(jobID: string) {
    //   return this.http.get<ApiResponse>(`/JobSurvey/HccSavedReports/${jobID}`).pipe(retry(3), switchMap(x => {
    //     return of(x as any);
    //   }));
    // }

    GetHcc2SavedImages(jobID: string) {
      return this.http.get<ApiResponse>(`/JobSurvey/GetHcc2SavedImages/${jobID}`).pipe(retry(3), switchMap(x => {
        return of(x as any);
      }));
    }

    uploadAttachment(jobID: string, formData: any): Observable<any> {
      return this.http.post<ApiResponse>('/JobSurvey/uploadAttachment/'+jobID, formData).pipe(retry(3), switchMap(x => {
        return of(x as any);
      }));
    }

    addAttachmentToJob(payload: any) {
      return this.http.post<ApiResponse>('/JobSurvey/AddAttachmentToJob', payload).pipe(retry(3), switchMap(x => {
        return of(x as any);
      }))
    }

    deleteAttachment(attachmentID: string) {
      return this.http.delete<ApiResponse>('/jobSurvey/DeleteAttachmentFromJob/' + attachmentID).pipe(retry(3));
    }

    updateAttachmentToJob(id: string, formData: any) {
      return this.http.put<ApiResponse>('/jobSurvey/UpdateAttachment/' + id, formData).pipe(retry(3), switchMap(x => {
        return of(x.result);
      }));
    }

    previewSurveyDetails(id: string) {
      return this.http.get<ApiResponse>('/jobSurvey/PreviewSurveyDetails/' + id).pipe(retry(3), switchMap(x => {
        return of(x);
      }));
    }

    SendJobForApproval(jobID: string) {
      return this.http.put<ApiResponse>('/jobSurvey/SendJobForApproval/'+jobID, jobID ).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    UpdateSignature(formData: any) {
      return this.http.put<ApiResponse>('/jobSurvey/UpdateSignature', formData).pipe(retry(3), switchMap(x=>{
        return of(x);
      }))
    }

    acknowledgeReport(jobID: string, signature: any) {
      return this.http.put<ApiResponse>('/jobSurvey/AcknowledgeSurveyDetails/'+jobID, signature).pipe(retry(3), switchMap(x=>{
        return of(x);
      }))
    }

    reinspectedHolds(jobID: string) {
      return this.http.get<ApiResponse>('/jobSurvey/ReinspectedHolds/'+jobID).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    savedReports(jobID: string) {
      return this.http.get<ApiResponse>('/jobSurvey/SavedReports/'+jobID).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    SendToClient(jobID: string, fileDetails: any, emails: Array<string> = []) {
      return this.http.post<ApiResponse>('/jobSurvey/SendToClient/'+jobID, {
        "fileDetails": fileDetails,
        "emailIDs": emails
      }).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    AcknowledgeTrimmingSurveyDetails(jobID: string, trimType: string, signature: any) {
      return this.http.put<ApiResponse>(`/jobSurvey/AcknowledgeTrimmingSurveyDetails/${jobID}/${trimType}`, signature).pipe(retry(3), switchMap(x=>{
        return of(x);
      }))
    }

    UploadLoadingSequenceTemplate(files: File[], jobID: string, worksheetName: string, isSave: boolean = false): Observable<ExcelImportResponse> {
      const formData = new FormData();
      formData.append('', files[0]);
      return this.http.post<ExcelImportResponse>(`/jobSurvey/UploadLoadingSequenceTemplate?isSave=${isSave}&worksheetName=${worksheetName}&jobID=${jobID}`, formData).pipe(retry(3), switchMap(x=>{
        return of(x as ExcelImportResponse);
      }))
    }

    DeleteHccFiles(jobID: string) {
      return this.http.delete<ApiResponse>('/jobSurvey/DeleteHccFiles/' + jobID).pipe(retry(3));
    }

    JobsByDateRange(fromDate: any, toDate: any) {
      return this.http.get<ApiResponse>(`/jobSurvey/JobsByDateRange?fromDate=${fromDate}&toDate=${toDate}`).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    ExportJobsData(payload: any[]) {
      return this.http.post(`/JobSurvey/ExportJobsData`, payload, {responseType: 'blob',});
    }

    LoadingSequenceDetails(jobID: string) {
      return this.http.get<ApiResponse>(`/JobSurvey/LoadingSequenceDetails?jobID=${jobID}`).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    UploadTidesTemplate(files: File[], tidesInput: any, payload: any): Observable<ExcelImportResponse> {
      const formData = new FormData();
      formData.append('formData', files[0]);
      formData.append('tidesInput',tidesInput);
      formData.append('addtionalTidesInputs', payload)
      return this.http.post<ExcelImportResponse>(`/jobSurvey/UploadTidesTemplate/`, formData).pipe(retry(3), switchMap(x=>{
        return of(x as ExcelImportResponse);
      }))
    }

    GetDaysMonthAndYear() {
      return this.http.get<ApiResponse>(`/JobSurvey/GetDaysMonthAndYear/`).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    TidesDetailsByDayMonthAndYear( payload: any) {
      return this.http.post<ApiResponse>(`/JobSurvey/TidesDetailsByDayMonthAndYear/`, payload).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }
    
    GetHccSavedReportsByType(jobID: string, type: any) {
      let payload= {
        jobID: jobID,
        type: type
      }
      return this.http.post<ApiResponse>(`/JobSurvey/GetHccSavedReportsByType/`, payload).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    GetTADetails(jobID: string){
      return this.http.get<ApiResponse>(`/JobSurvey/GetTADetails?jobID=${jobID}`).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    SaveTADetails(jobID: string, payload: any){
      return this.http.post<ApiResponse>(`/JobSurvey/SaveTADetails?jobID=${jobID}`,payload).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    AcknowledgeTADetails(jobID: string, signature: any, chiefOfficerName: string){
      let payload= {
        jobID: jobID,
        taDetailsSignature: signature,
        chiefOfficerName: chiefOfficerName
      }
      return this.http.put<ApiResponse>(`/jobSurvey/AcknowledgeTADetails/`, payload).pipe(retry(3), switchMap(x=>{
        return of(x);
      }))
    }

    ExportTADetails(jobID: string) {
      return this.http.get(`/JobSurvey/ExportTADetails?jobID=${jobID}`).pipe(retry(3), switchMap(x=>{
        return of(x);
      }));
    }
    
    GetSafetyCheckListItems() {
      return this.http.get<ApiResponse>(`/jobSurvey/GetSafetyCheckListItems/`).pipe(retry(3), switchMap(x=>{
        return of(x);
      }))
    }

    GenerateSafetyCheckListCertificate(jobID: string, payload: any, action: string): Observable<any> {
      if(action === FILE_ACTIONS.VIEW) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.post<Blob>(`/JobSurvey/GenerateSafetyCheckListCertificate/${jobID}`, payload, {
            headers,
            observe: 'response',
            responseType: 'blob' as 'json',
        }).pipe(retry(1));
      } else {
        return this.http.post<ApiResponse>(`/JobSurvey/GenerateSafetyCheckListCertificate/${jobID}`, payload).pipe(retry(3),
        switchMap(res => {
          return of(res.result);
        }));
      }
    }

    GetSavedSafetyCheckListReport(jobID: string) {
      return this.http.get<ApiResponse>(`/JobSurvey/GetSavedSafetyCheckListReport/${jobID}`).pipe(retry(3), switchMap(x => {
        return of(x);
      }))
    }

    viewSavedFile = (
      data: any,
      // type: string,
      // action: FILE_ACTIONS.VIEW,
      // fileName?: string
      ) => {

      let file = data.reportFileUrl
      let fileURL = data.reportFileUrl
      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      // if(action == FILE_ACTIONS.DOWNLOAD) {
      //     a.download = fileName
      // }

      document.body.appendChild(a);
      a.click();
    }

    viewFile = (
      data: any,
      type: string,
      action: FILE_ACTIONS.VIEW | FILE_ACTIONS.DOWNLOAD,
      fileName?: string
    ) => {
      console.log("Action: ",action, );

      if(action === FILE_ACTIONS.DOWNLOAD) {
        let file = data.reportFileUrl
        let fileURL = data.reportFileUrl
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        if(action == FILE_ACTIONS.DOWNLOAD) {
          a.download = fileName
        }
      } else{
        let file = new Blob([data], { type })
        let fileURL = window.URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
      }
      document.body.appendChild(a);
      a.click();
    }
}


