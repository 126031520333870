<form [formGroup]="commentsForm">
    <div class="modal open" id="modalComments" style="height: auto;">
        <div class="modal__header">
            <h2>Comments</h2>
            <button type="button" class="btn btn--icon" (click)="close()">
                <i class="material-icons">close</i>
            </button>
        </div>
        <div class="modal__content"  #scroll>

            <ul class="list-comments">
                <li class="comment" *ngFor="let comments of CommentList; let i = index" style="margin-bottom: -2.5rem">
                    

                    <div [ngClass] = "isMyComment(comments) ? 'shift-right' : 'shift-left'">

                    
                    <span class="comment__date">{{comments?.commentedDateTime | localDatetime|date: "dd MMM yyyy HH:mm"}}</span> -
                    <span class="comment__user">{{ isMyComment(comments) ? 'You' : comments.user?.firstName }}</span>
                    <div class="comment__text" [ngClass] = "isMyComment(comments) ? '' : 'wid'">
                        {{comments.comment}}
                    </div>

                    <!-- [ngClass] = "isMyComment(comments) ? 'shift-right' : 'shift-left'" <ngx-reply-comment [replyComment]="comments" [commentData]="jobid"></ngx-reply-comment> -->


                    <!-- <input *ngIf="editing && replyIndex == i" nbInput fieldSize="small" formControlName="textareaReply" name="textareaReply"> -->
                    <textarea *ngIf="editing && replyIndex == i" class="form-control form-control__textarea"
                        placeholder="Reply" formControlName="textareaReply" name="textareaReply"></textarea>
                        <a *ngIf="replyIndex != i" (click)="onreplyclick(i)" class="btnSize">Reply</a>&nbsp;
                        <!-- <div *ngIf="isMyComment(comments)"> -->
                            <a *ngIf="replyIndex != i && isMyComment(comments)" (click)="oneditclick1(comments)" class="btnSize">Edit</a>&nbsp;
                            <a *ngIf="replyIndex != i && isMyComment(comments)" (click)="delete(comments)" class="btnSize">Delete</a>
                        <!-- </div> -->
                        
                        <a *ngIf="editing && replyIndex == i" (click)="replycomment(comments)" class="btnSize">Add</a>&nbsp;
                        <a *ngIf="editing && replyIndex == i" (click)="cancelReply(i)" class="btnSize">Cancel</a>

                    <ul class="list-comments list-comments--nested">
                        <li class="comment">
                            <div class="editDiv" *ngFor="let subcmnts of comments.subComment; let j = index" style="margin-bottom: 1rem;">
                                <!-- <ngx-comment [CommentList]="subcmnts" [commentData]="jobid"></ngx-comment> -->
                                <!-- <div [ngClass] = "isMyComment(comments) ? 'shift-right' : 'shift-left'"> -->
                                <span class="comment__date">{{subcmnts?.commentedDateTime | localDatetime|date: "dd MMM yyyy HH:mm"}}</span> -
                                <span class="comment__user">{{ isMyComment(subcmnts) ? 'You' : subcmnts.user?.firstName }}</span>

                                <div class="comment__text" [ngClass] = "isMyComment(comments) ? '' : 'wid'">
                                    {{subcmnts.comment}}
                                </div>

                                    <div *ngIf="isMyComment(subcmnts)">
                                        <a (click)="oneditclick1(subcmnts)" class="btnSize">Edit</a>&nbsp;
                                        <a (click)="delete(subcmnts)" class="btnSize">Delete</a>
                                    </div>
                                <!-- </div> -->
                            </div>
                        </li>
                    </ul>
                </div>
                </li>
            </ul>

        </div>

        <div class="modal__comment-form">

            <div *ngIf="!makeEdit">
                <textarea class="form-control form-control__textarea" placeholder="Enter your comments"
                    formControlName="textarea" name="textarea"></textarea>
                

                <button type="button" class="btn btn--primary btn--condensed" style="float: right;margin-top: 1rem;"
                    (click)="addComment()">
                    Add Comment
                </button>
            </div>


            <div *ngIf="makeEdit">
                <textarea class="form-control form-control__textarea" placeholder="Edit your comments"
                    formControlName="Comment" name="Comment"></textarea>

                    <button type="button" class="btn btn--primary btn--condensed"
                    style="float: right;margin-top: 1rem;" (click)="cancel()">
                    Cancel
                </button>
                <button type="button" class="btn btn--primary btn--condensed" style="float: right;margin-top: 1rem;margin-right: 1rem;"
                    (click)="update(subcmnts)">
                    Update
                </button>
                
            </div>

            

        </div>
    </div>
</form>





<!-- <form [formGroup]="commentsForm">
    <nb-card class="cardsize" >
        <nb-card-header>
            <div class="modal__header">
            <h2>Comments</h2>
            <button type="button" class="btn btn--icon" (click)="close()">
                <i class="material-icons">close</i>
            </button>
        </div>
            
        </nb-card-header>
        
        <nb-card-body class="cardBody">
            <div *ngFor="let comments of CommentList">
                <div class="commentsdiv">
                     <span class="commentsTags">{{comments?.commentedDateTime | date:'dd MMM yyyy hh:mma'}}</span> -
                <span class="commentsTags">{{ isMyComment(comments) ? 'You' : comments.user?.firstName }}</span>
                <div class="comment__text">
                    {{comments.comment}}
                </div>
                    <ngx-reply-comment [replyComment]="comments" [commentData]="jobid"></ngx-reply-comment>
                </div>
                <div class="editDiv" *ngFor="let subcmnts of comments.subComment">
                    <ngx-comment [CommentList]="subcmnts" [commentData]="jobid"></ngx-comment>
                </div>
            </div>
            
            <div class="commnetsTextDiv">
                <textarea class="form-control form-control__textarea" placeholder="Enter your comments"
                formControlName="textarea" name="textarea"></textarea>
            </div>
            <button type="button" style="float: right;" class="btn btn--primary btn--condensed" (click)="addComment()">
                Add Comment
            </button>
        </nb-card-body>
    </nb-card>

</form> -->