
<div *ngIf="!isError;else temp">
  <div *ngIf="value && dotCheck != '.' ">
    {{ value | number:'1.0-3'}}
  </div>

  <div *ngIf="value && dotCheck == '.' ">
    {{ value | number:'1.3-3'}}
  </div>

  <div *ngIf="!value">
    {{ 0 }}
  </div>
 
</div>

<ng-template #temp>
  {{ value  }}
  <button type="button" class="btn btn--icon" (click)="navigateToOriginalPage()">
    <i class="material-icons critical" title="Missing Data">warning</i>
  </button>
</ng-template>

<!-- <div *ngIf="!isError && value!='0'">
  {{ value }}
</div>

<ng-template *ngIf="isError && filteredValue">
  <button type="button" class="btn btn--icon" (click)="navigateToOriginalPage()">
    <i class="material-icons critical" title="Missing Data">warning</i>
  </button>
</ng-template> -->