import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule, } from 'angular2-chartjs';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbListModule,
  NbInputModule,
  NbTabsetModule,
  NbTimepickerModule,
  NbRadioModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  // TinyMCEComponent,
  ChartjsLineComponent,
  ConfirmDialogComponent,
  ToggleChatButtonComponent,
  StatusCardComponent,

} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { CommentComponent } from './components/comment/comment.component';

import { PortNameDirective } from './directives/port.directive';
import { UploadTemplateComponent } from './components/upload-template/upload-template.component'
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ModelWithNameDirective } from './directives/model-with-name.directive';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ViewPhotoComponent } from './components/view-photo/view-photo.component';
import { RedirectComponent } from './components/auth/redirect/redirect.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { VesselDetailsComponent } from './components/vessel-details/vessel-details.component';
import { VesselHydrostaticDetailsComponent } from './components/vessel-hydrostatic-details/vessel-hydrostatic-details.component';
import { NewCommentComponent } from './components/new-comment/new-comment.component';
import { SurveyCalculateComponent } from './components/survey-calculate/survey-calculate.component';
import { VideoPhotoComponent } from './components/video-photo/video-photo.component';
import { LocalDatetimePipe } from './pipes/local-datetime.pipe';
import { ReplyCommentComponent } from './components/reply-comment/reply-comment.component';
import { PhotosCommentsComponent } from './components/photos-comments/photos-comments.component';
import { ShowErrorsComponent } from './components/show-errors/show-errors.component';
import { VesselNameComponent } from './components/vessel-name/vessel-name.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { EditPhotoComponent } from './components/edit-photo/edit-photo.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbListModule,
  ChartModule,
  NbIconModule,
  NbCardModule,
  NbInputModule,
  NbTabsetModule,
  NbTimepickerModule,
  PdfViewerModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  // TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  ChartjsLineComponent,
  ConfirmDialogComponent,
  ToggleChatButtonComponent,
  StatusCardComponent,
  CommentComponent,
  NotificationListComponent,
  UploadTemplateComponent,
  AttachmentComponent,
  ViewPhotoComponent,
  EditPhotoComponent,
  PageHeadingComponent,
  NewCommentComponent,
  SurveyCalculateComponent,
  VideoPhotoComponent,
  ReplyCommentComponent,
  VesselNameComponent,
  PdfViewerComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  LocalDatetimePipe,
];

const DIRECTIVES = [PortNameDirective, ModelWithNameDirective]
@NgModule({
  imports: [CommonModule, ...NB_MODULES, FileUploadModule, ReactiveFormsModule, RouterModule, NbRadioModule],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES, RedirectComponent,
    LoginComponent,
    VesselDetailsComponent,
    VesselHydrostaticDetailsComponent,
    PhotosCommentsComponent,
    ShowErrorsComponent,
    VesselNameComponent,

  ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
