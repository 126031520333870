<!-- <input *ngIf="editing" nbInput fieldSize="small" formControlName="textareaReply" name="textareaReply">
<button *ngIf="!editing" type="button" status="primary" class="commentsTagsReply commentsButtons"
    (click)="onreplyclick(comments.id)">Reply</button>

<button *ngIf="editing" type="button" status="primary" class="commentsTagsReply commentsButtons"
    (click)="replycomment(comments)">Add</button>&nbsp;

<button *ngIf="editing" type="button" status="primary" (click)="cancel()"
    class="commentsTagsReply commentsButtons">Cancel</button> -->

    <form [formGroup]="commentsForm">
    <input *ngIf="editing" nbInput fieldSize="small"  formControlName="textareaReply" name="textareaReply">
<button *ngIf="!editing" type="button" status="primary" class="commentsTagsReply commentsButtons"
    (click)="onreplyclick()">Reply</button>

<button *ngIf="editing" type="button" status="primary" class="commentsTagsReply commentsButtons"
    (click)="replycomment(replyComment)">Add</button>&nbsp;

<button *ngIf="editing" type="button" status="primary" (click)="cancel()"
    class="commentsTagsReply commentsButtons">Cancel</button>
</form>