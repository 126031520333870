<div class="header-container">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <!-- <a class="logo" href="#" (click)="navigateHome()"><strong>iDraft</strong><span class="text-admin">admin</span></a> -->
    <img src="./../../../../assets/images/logo_intertek.png" class="logo" alt="" srcset="">
  </div>
  <!-- <h6 *ngIf="isTestEnvironment()" class="blinker">Testing Environment</h6> -->
  <!-- <nb-select size="small" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div *ngIf="claims !== null" class="header-container">
  <nb-actions size="medium" >

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <!-- <nb-action class="control-item" icon="bell-outline" ></nb-action> -->
    <!-- <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture"
        nbContextMenuTag="user-menu">
      </nb-user>
    </nb-action> -->
    <nb-action class="control-item" routerLink="/pages/jobs" routerLinkActive="active" activeLinkOptions="{exact:
    false}" *nbIsGranted="['view', 'jobs']">Jobs
    </nb-action>
    <nb-action class="control-item" routerLink="/pages/vessels" routerLinkActive="active" activeLinkOptions="{exact:
    false}" *nbIsGranted="['view', 'vessels']">Vessels</nb-action>
    <nb-action class="control-item" routerLink="/pages/client-master" routerLinkActive="active" activeLinkOptions="{exact:
    false}" *nbIsGranted="['view', 'clients']">Clients</nb-action>
    <nb-action class="control-item" routerLink="/pages/admin" routerLinkActive="active" activeLinkOptions="{exact:
    false}" *nbIsGranted="['view', 'admin']">Admin</nb-action>

    <!-- <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture"
        nbContextMenuTag="user-menu">
      </nb-user>
    </nb-action> -->



  </nb-actions>
  <div *ngIf="screenWidth <= 992">
    <button outline nbButton [nbContextMenu]="items" nbContextMenuTag="more-items" style="background: transparent;
    border: transparent; width: 40px; height: 40px;">
    <img src="../../../../assets/images/more-vertical-outline.svg"
      class="menuForSmallScreen">
  </button>
  </div>
  <nb-user name="{{userName}}"
    [nbContextMenu]="menuItems"
    nbContextMenuTag="my-context-menu">
  </nb-user>
  <!-- <button type="button" class="btn btn--icon" (click)="editUserProfile()">
    <i class="material-icons">person</i>
  </button>

  <button class="btn-logout" (click)="onLogout()">Log out</button> -->
  <ngx-notification-list [visible]="showNotifications" (readNotification)="toggleNotifications($event)" (minusOne)="setCount($event)">
  </ngx-notification-list>
  <button type="button" class="btn btn--icon btn--alert" (click)="toggleNotifications($event)">
    <i class="material-icons">notifications_none</i>
    <div *ngIf="(notificationCount$|async).length > 0">
        <span class="alert-badge" *ngIf="(notificationCount$|async).length">{{(notificationCount$|async).length}}</span>
    </div>
    <div *ngIf="(notificationCount$|async).length == 0">
      <span class="alert-badge">{{ 0 }}</span>
    </div>

    <!-- <span class="alert-badge" *ngIf="(notificationCount$|async).length && setNumber">{{(notificationCount$|async).length - 1}}</span> -->
  </button>
</div>
