import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <footer class="footer"><p>© 2021 Intertek Group plc</p></footer>
  `,
})
export class FooterComponent {
}
