import { Injectable, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthJWTInterceptor, NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf, of } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
} from './utils';
import { UserData } from './data/users';
import { UserService } from './services/users.service';

// import { MockDataModule } from './mock/mock-data.module';
// import { AuthInterceptor } from './utils/auth-interceptor';
import { HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { flatten } from '@angular/compiler';
import { VesselData } from './data/vessels';
import { VesselMasterData } from './data/vesselMaster'
import { VesselService } from './services/vessels.service';
import { VesselMasterService } from './services/vessel-master.service'
import { DataModule } from './services/data.module';
import { AuthGuardWithForcedLogin } from './guards/auth-guard.service';
import { JobsData } from './data/jobs';
import { JobsService } from './services/jobs.service';
import { UrlInterceptor } from './utils/url-interceptor';
import { FirebaseService } from './services/firebase.service';
import { PortsData } from './data/ports';
import { PortsService } from './services/ports.service';
import { BranchesData } from './data/branches';
import { BranchesService } from './services/branches.service';
import { LookupData } from './data/lookup';
import { LookupService } from './services/lookup.service';
import { AuthConfig } from 'angular-oauth2-oidc';
import { authConfig } from './config/auth.config';
import { clientData } from './data/clients';
import { ClientsService } from './services/clients.service';
import { AuthService } from './services/auth.service';
//import { VesselMasterService } from './services/vessel-master.service';


const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: VesselData, useClass: VesselService },
  { provide: VesselMasterData, useClass: VesselMasterService },
  { provide: clientData, useClass: ClientsService },
  { provide: JobsData, useClass: JobsService },
  { provide: PortsData, useClass: PortsService },
  { provide: BranchesData, useClass: BranchesService },
  { provide: LookupData, useClass: LookupService },
  FirebaseService,
  AuthService,
];

const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
]

const GUARDS = [
  AuthGuardWithForcedLogin
]

const OIDC_AUTH_PROVIDERS = [
  {
    provide: AuthConfig, useValue: authConfig
  }
]

@Injectable()
export class NbSimpleRoleProvider extends NbRoleProvider {
  constructor(private authService: AuthService) {
    super();
  }
  getRole() {
    // here you could provide any role based on any auth flow
    return of((this.authService.identityClaims as any)?.role);
  }
}

export const NB_CORE_PROVIDERS = [
  // ...MockDataModule.forRoot().providers,
  ...DataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...INTERCEPTORS,
  ...GUARDS,
  // ...NbAuthModule.forRoot({

  //   strategies: [
  //     NbPasswordAuthStrategy.setup({
  //       name: 'email',
  //       baseEndpoint: 'https://ccb-dev.azure-api.net/ms/api' + '/auth/',
  //       login: {
  //         endpoint: 'register',
  //       },
  //       refreshToken: {
  //         endpoint: 'refresh',
  //       },
  //       logout: {
  //         endpoint: 'logout',
  //         method: 'delete',
  //         redirect: {
  //           success: '/auth/login',
  //           failure: '/auth/login',
  //         },
  //       },
  //       register: false,
  //       resetPass: false,
  //       requestPass: false,
  //       token: {
  //         class: NbAuthJWTToken,
  //         key: 'authData.accessToken',
  //       },

  //     }),
  //   ],
  //   forms: {
  //     login: {
  //       redirectDelay: 0,
  //       showMessages: {
  //         success: true,

  //       },
  //     },
  //   }
  // }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: 'dashboard',
      },
      MasterMariner: {
        parent: 'guest',
        create: ['jobs', 'vessels'],
        edit: ['jobs', 'vessels',],
        remove: ['jobs', 'vessels',],
        view: ['jobs', 'vessels', 'clients', 'users', 'ports', 'branches'],
      },
      Admin: {
        parent: 'guest',
        create: ['jobs', 'vessels', 'clients', 'users', 'ports', 'branches'],
        edit: ['jobs', 'vessels', 'clients', 'users', 'ports', 'branches',],
        remove: ['jobs', 'vessels', 'clients', 'users', 'ports', 'branches',],
        view: ['jobs', 'vessels', 'clients', 'users', 'ports', 'branches', 'admin'],
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
  ...OIDC_AUTH_PROVIDERS,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    // NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        // { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
        // {
        //   provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: (req) => {
        //     return req.url.includes('auth/refresh');
        //   }
        // },
      ],
    };
  }
}
