import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, Observable } from "rxjs";
import { FieldsManagerInput, addCommentFields,FieldsManagerOutput, Replycomments } from "../data/jobs";
import { filter, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private readonly subscribeEndPoint: string = "Subscribe";
  private readonly lockFieldEndPoint: string = "LockField";
  private readonly unLockAndUpdateFieldEndPoint: string =
    "UnLockAndUpdateField";

    private readonly getUnreadJobComments: string = "GetUnreadJobComments";
    private readonly getUnreadAttachmentComments: string = "GetUnreadAttachmentComments";
    private readonly getFieldDetailsForJobInfo: string = "GetFieldDetailsForJobInfo";

  public hubConnection: signalR.HubConnection;
  //public CommentshubConnection: signalR.HubConnection;
  public isHubConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCommentsHubConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isSubscribed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCommentSubscribed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public initialFields$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public finalFields$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  public CommentsinitialFields$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public CommentsinitialFieldsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public attachmentCommentsinitialFields$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public attachmentCommentsinitialFieldsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isattachmentCommentSubscribed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  


  public initialFieldsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public finalFieldsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public unreadAttachmentComments$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    public jobInfoFields$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    public jobInfoFieldsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
    //commentCount
    public unreadJobComments$: BehaviorSubject<number> = new BehaviorSubject(0);
    public unreadJobCommentsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(
      false
    );

  constructor() {
    this.signalRConnection();


    this.isHubConnected$.pipe(filter(connected => connected)).subscribe(connected => {
      this.hubConnection.on("GetFieldDetailsForInitialJob", (fields) => {
        console.log("get initial fields for job:", fields);
        this.initialFields$.next(fields);
        this.initialFieldsLoaded$.next(true);
      });
      this.hubConnection.on("GetFieldDetailsForFinalJob", (fields) => {
        console.log("get final fields for job:", fields);
        this.finalFields$.next(fields);
        this.finalFieldsLoaded$.next(true);
      });
      this.hubConnection.on("GetCommentsForJob", (fields) => {
        console.log(fields);
        console.log("Get All Comments for job:", fields);
        this.CommentsinitialFields$.next(fields);
        this.CommentsinitialFieldsLoaded$.next(true);
      });
      this.hubConnection.on("GetCommentsForAttachment", (fields) => {
        console.log(fields);
        console.log("Get All Comments for attachment:", fields);
        this.attachmentCommentsinitialFields$.next(fields);
        this.attachmentCommentsinitialFieldsLoaded$.next(true);
      });
      this.hubConnection.on(this.getFieldDetailsForJobInfo, (fields) => {
        console.log("get jobInfo fields for job:");
        console.table(fields);
        this.jobInfoFields$.next(fields);
        this.jobInfoFieldsLoaded$.next(true);
    });
    this.hubConnection.on(this.getUnreadJobComments, (fields) => {
      console.log('unread job comments count', fields);
      if(fields.length == 0) return this.unreadJobComments$.next(0);
      this.unreadJobComments$.next(fields[0].unreadCount);
      //  this.unreadJobCommentsLoaded$.next(true);
  });
  this.hubConnection.on(this.getUnreadAttachmentComments, (fields) => {
    console.log('unread attachment comments count', fields);
    this.unreadAttachmentComments$.next(fields);
     //this.unreadAttachmentLoaded$.next(true);
  });

      this.hubConnection.onreconnected(_ => {
        this.isHubConnected$.next(true);
      })
    });

  }

  public async signalRConnection() {
    console.log("creating signalR connection");
    if (this.hubConnection && (this.hubConnection.state == 'Connected' || this.hubConnection.state == 'Connecting'))
      return this.hubConnection;

    else {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.jobSignalREndpoint, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          // logMessageContent: true
        }).withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
      Object.defineProperty(WebSocket, "OPEN", { value: 1 });
      try {
        console.log("starting signalR connection");
        await this.hubConnection
          .start();
        console.log(" signalR connection started");
        this.isHubConnected$.next(true);
        return this.hubConnection;
      } catch {
        return this.isHubConnected$.next(false);//TODO  on error do we need to retry , if yes how many times?
      }
    }
  }
 //job info
 public async invokeJobInfo(jobId: string) {
  console.log("invoke job info");
  if (!this.isHubConnected$.value == true) {
      await this.signalRConnection();
  };
  return await this.hubConnection.invoke(this.getFieldDetailsForJobInfo, jobId)
  }


public getJobInfoFieldList(): Observable<Array<any>> {
  return this.jobInfoFields$.asObservable();
}

public isJobInfoFieldsLoaded(): Observable<boolean> {
  return this.jobInfoFieldsLoaded$.asObservable();
}

public subscribeTojobifoEvents(jobId: string) {
  console.log("subscribeTojobinfoEvents");
  if (!this.isHubConnected$ == true) {
    this.signalRConnection();
  };

  return this.isHubConnected$.pipe(filter(connected => connected), take(1))
    .subscribe(async () => {
      console.log("subscribeTojobinfoEvents in the observable");
      try {
        await this.hubConnection
          .invoke(this.subscribeEndPoint, jobId)
        console.log("subscribeTojobinfoEvents done");
        this.jobInfoFieldsLoaded$.next(true);

      } catch(error) {
        console.log('subscribeTojobinfoEvents error:', error);
      }
      //this.isCommentSubscribed$.next(true);
    })
}

//Comments Cout
public getUnreadJobCommentsCount(): Observable<number> {
  return this.unreadJobComments$.asObservable();
}


public async invokeUnreadJobComment(userId: string, jobId: string) {
  console.log("invoke", this.getUnreadJobComments);
  if (!this.isHubConnected$.value == true) {
      await this.signalRConnection();
  };
  const data = await this.hubConnection.invoke(this.getUnreadJobComments, userId, jobId);
}

public CommentsCountFields(): Observable<boolean> {
  return this.unreadJobCommentsLoaded$.asObservable();
}

public subscribeToCommentsCountEvents(jobId: string) {
  console.log("subscribeToComments Count Events");
  if (!this.isHubConnected$ == true) {
    this.signalRConnection();
  };

  return this.isHubConnected$.pipe(filter(connected => connected), take(1))
    .subscribe(async () => {
      console.log("subscribeToComments Count Events in the observable");
      try {
        await this.hubConnection
        .invoke(this.subscribeEndPoint, jobId)
      console.log("subscribeToCommentsEvents done");
      this.unreadJobCommentsLoaded$.next(true);
      } catch(error) {
        console.log('subscribeToCommentsEvents error:', error);
      }
      
    })
}


//attachment 
public async invokeAttachmentComments(userId: string, jobId: string) {
  console.log("invoke", this.getUnreadJobComments);
  if (!this.isHubConnected$.value == true) {
      await this.signalRConnection();
  };
  const data = await this.hubConnection.invoke(this.getUnreadJobComments, userId, jobId);
}

public subscribeToAttachmentCommentsEvents(jobId: string) {
  console.log("subscribeToattachCommentsEvents");
  if (!this.isHubConnected$ == true) {
    this.signalRConnection();
  };

  return this.isHubConnected$.pipe(filter(connected => connected), take(1))
    .subscribe(async () => {
      console.log("subscribeToattachCommentsEvents in the observable");
      try {

        await this.hubConnection
          .invoke(this.subscribeEndPoint, jobId)
        console.log("subscribeToattachCommentsEvents done");
        this.isattachmentCommentSubscribed$.next(true);
      } catch(error) {
        console.log('subscribeToattachCommentsEvents error:', error);
      }
    })
}

public async invokeattachmentComments(Id: string, Type:string) {
  console.log("GetCommentsForAttachment");
  if (!this.isHubConnected$.value == true) {
    await this.signalRConnection();
  };
  return await this.hubConnection
    .invoke("GetCommentsForAttachment", Id,Type)
  
}

public getAttachmentCommentslist(): Observable<Array<any>> {
  return this.attachmentCommentsinitialFields$.asObservable();
}
public attachmentCommentsinitialFields(): Observable<boolean> {
  return this.attachmentCommentsinitialFieldsLoaded$.asObservable();
}
public isattachmentCommentSubscribed(): Observable<boolean> {
  return this.isattachmentCommentSubscribed$.asObservable();
}

//count attachmnet
public async invokeUnreadAttachmentComment(userId: string, attachmentIds: Array<string>) {
  console.log('invoke', this.getUnreadAttachmentComments);
  if (!this.isHubConnected$.value == true) {
      await this.signalRConnection();
  };
  const data = await this.hubConnection.invoke(this.getUnreadAttachmentComments, userId, attachmentIds);
}

public getUnreadAttachmentCommentsCount(): Observable<Array<any>> {
  return this.unreadAttachmentComments$.asObservable();
}





  public subscribeToJobLockEvents(jobId: string) {
    console.log("subscribeToJobLockEvents");
    if (!this.isHubConnected$ == true) {
      this.signalRConnection();
    };

    return this.isHubConnected$.pipe(filter(connected => connected), take(1))
      .subscribe(async () => {
        try {
          await this.hubConnection
            .invoke(this.subscribeEndPoint, jobId)
          console.log("subscribeToJobLockEvents done");
          this.isSubscribed$.next(true);

        } catch(error) {
          console.log('subscribeToJobLockEvents error:', error);
        }
      })
  }

  public subscribeToCommentsEvents(jobId: string) {
    console.log("subscribeToCommentsEvents");
    if (!this.isHubConnected$ == true) {
      this.signalRConnection();
    };

    return this.isHubConnected$.pipe(filter(connected => connected), take(1))
      .subscribe(async () => {
        console.log("subscribeToCommentsEvents in the observable");
        try {
          await this.hubConnection
            .invoke(this.subscribeEndPoint, jobId)
          console.log("subscribeToCommentsEvents done");
          this.isCommentSubscribed$.next(true);

        } catch(error) {
          console.log('subscribeToCommentsEvents error:', error);
        }
      })
  }
  

 

  public async invokeInitialJob(jobId: string) {
    console.log("invokeInitialJob ");
    if (!this.isHubConnected$.value == true) {
      await this.signalRConnection();
    };
    return await this.hubConnection
      .invoke("GetFieldDetailsForInitialJob", jobId)
    
  }
  public async invokeComments(Id: string, Type:string) {
    console.log("GetCommentsForJob");
    if (!this.isHubConnected$.value == true) {
      await this.signalRConnection();
    };
    return await this.hubConnection
      .invoke("GetCommentsForJob", Id,Type)
    
  }

//   public async invokeJobComments(id: string) {
//     const commentType = "Job";
//     console.log('GetCommentsForJob invoked');
//     if (!this.isHubConnected$.value == true) {
//         await this.signalRConnection();
//     };
//     return await this.hubConnection.invoke("GetCommentsForJob", id, commentType)
// }
 

  public async invokeFinalJob(jobId: string) {
    console.log("invokeFinalJob ");
    return await this.hubConnection
      .invoke("GetFieldDetailsForFinalJob", jobId)
    // .then((data) => {
    //   console.log("GetFieldDetailsForFinalJob, invoked:", data);
    // });
  }
 

  public isHubConnected(): Observable<boolean> {
    return this.isHubConnected$.asObservable();
  }
  public isCommentsHubConnected(): Observable<boolean> {
    return this.isHubConnected$.asObservable();
  }
  

  public getCommentslist(): Observable<Array<any>> {
    return this.CommentsinitialFields$.asObservable();
  }
  
  public CommentsinitialFields(): Observable<boolean> {
    return this.CommentsinitialFieldsLoaded$.asObservable();
  }
  
  public isSubscribed(): Observable<boolean> {
    return this.isSubscribed$.asObservable();
  }
  public isCommentSubscribed(): Observable<boolean> {
    return this.isCommentSubscribed$.asObservable();
  }
  

  public getInitialFieldList(): Observable<Array<any>> {
    return this.initialFields$.asObservable();
  }

  public getFinalFieldList(): Observable<Array<any>> {
    return this.finalFields$.asObservable();
  }

  public isInitialFieldsLoaded(): Observable<boolean> {
    return this.initialFieldsLoaded$.asObservable();
  }

  public isFinalFieldsLoaded(): Observable<boolean> {
    return this.finalFieldsLoaded$.asObservable();
  }

  public async lockField(field: FieldsManagerInput) {
    // if (!this.isHubConnected$.value == true) {
    //   await this.signalRConnection();
    // };
    // if(!this.isSubscribed$.value == true) {
    //   this.subscribeToJobLockEvents(field.jobID);
    // }
    const data = await this.hubConnection.invoke("LockField", field);
    console.log("lock field:", data);
  }

  public async unlockAndUpdateField(field: FieldsManagerOutput) {
    const data = await this.hubConnection.invoke("UnLockAndUpdateField", field);
    console.log("unlock field:", data);
  }

  public async invokeAddComment(field: addCommentFields) {

    const data = await this.hubConnection.invoke("AddComment", field);
    console.log("AddComment", field);

  }
  public async invokeReplyComment(field: Replycomments) {

    const data = await this.hubConnection.invoke("AddComment", field);
    console.log("AddComment", field);

  }

  public async invokeDeleteComment(field) {
    const data = await this.hubConnection.invoke("DeleteComment",field);
    console.log("DeleteComment",field);
  }

  public async invokeEditComment(field) {

    const data = await this.hubConnection.invoke("EditComment", field);
    console.log("EditComment", field);

  }

//
public async invokeAttachmentAddComment(field: addCommentFields) {

  const data = await this.hubConnection.invoke("AddComment", field);
  console.log("AddComment", field);

}
public async invokeAttachmentReplyComment(field: Replycomments) {

  const data = await this.hubConnection.invoke("AddComment", field);
  console.log("AddComment", field);

}

public async invokeAttachmentDeleteComment(field) {
  const data = await this.hubConnection.invoke("DeleteComment",field);
  console.log("DeleteComment", field);
}

public async invokeAttachmentEditComment(field) {

  const data = await this.hubConnection.invoke("EditComment", field);
  console.log("EditComment", field);

}

  public unsubscribe(jobId: string) {
    if (!this.isSubscribed$.value) return;
    this.hubConnection
      .invoke("Unsubscribe", jobId)
      .then((data) => {
        console.log("unsubscribe successful for:", jobId);
        this.isSubscribed$.next(false);
        this.resetFields();
      })
      .catch((error) => {
        console.log("unsubscribe failed for:", jobId + ": ", error);
      });
  }

  private resetFields() {
    this.finalFields$.next([]);
    this.initialFields$.next([]);
    this.initialFieldsLoaded$.next(false);
    this.finalFieldsLoaded$.next(false);
    this.CommentsinitialFields$.next([]);
    this.CommentsinitialFieldsLoaded$.next(false);
    this.unreadJobCommentsLoaded$.next(false);
    this.attachmentCommentsinitialFields$.next([]);
    this.attachmentCommentsinitialFieldsLoaded$.next(false);
    this.jobInfoFields$.next([]);
    this.jobInfoFieldsLoaded$.next(false);    
  }
}
