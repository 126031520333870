import { HttpHeaders } from "@angular/common/http";

export const saveFile = (data: any, type: string, fileName: string, action: "view" | "download" = "download") => {

    let file = new Blob([data], { type })
    let fileURL = window.URL.createObjectURL(file);
    // let wind = window.open(fileURL);
    // if (!wind) {
    var a = document.createElement('a');
    a.href = fileURL;
    a.target = '_blank';
    if(action == "download") {
        a.download = fileName
    }

    document.body.appendChild(a);
    a.click();
}

export const saveFileFromUrl = (url: string, fileName: string, action: "view" | "download" = "download") => {
    var a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    if(action === "download") {
        a.download = fileName
    }
    console.log("downloaddownload",a)
    document.body.appendChild(a);
    a.click();
    a.remove();
}


export const getFileNameFromHeader = (headers: HttpHeaders, header: string = 'Content-Disposition') => {

    let disposition = headers.get(header);
    let fileName;
    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            return fileName = matches[1].replace(/['"]/g, '');
        }
    }

}